import { typingTextIntro } from 'consts/data';
import { tooltipPostitionEnum } from 'models/IToolTip';
import { PopFromBottom } from 'ui-kit/popFromBottom';
import Tooltip from 'ui-kit/toolTip';
import { Typing } from 'ui-kit/typing';
import './style.css';
import { ReactNode } from 'react';
import { imageHandler } from 'utils/ImageHandler';

// export const HomeIntro = () => {
//   // const title = `SWIT Solutions`;
//   const title = `JoyTech - ג'ויטק`;
//   // const description = `כשהנדסה פוגשת חדשנות`;
//   const description = `Software & IT Solutions`;
//   const slogen = `Cuz We (En)Joy-Tech(nology)`;
//   const toolTip = 'לחצו כאן';

//   const ToolTipComponent = () => (
//     <div className="intro-link" onClick={() => console.log('onClick!!')}>
//       לחצו עלי לעוד פרטים מעניינים 
//     </div>
//   );

//   return (
//     <div className="home-intro">
//       {/* <Tooltip
//         component={<ToolTipComponent />}
//         content={toolTip}
//         position={tooltipPostitionEnum.Middle}
//         disabled={false}
//       > */}

//         <h1>{title}</h1>
//       <img
//         style={{ position: 'absolute',height:'300px' }}
//         alt=""
//         src={require('../../assets/pictures/Joytech2.png')}
//       />
//       <PopFromBottom>

//         <h1>{description}</h1>
//         {/* <h2>{slogen}</h2> */}
//       </PopFromBottom>

//       {/* </Tooltip> */}

//       {/* <Optional /> */}
//     </div>
//   );
// };

const Rating = () => <div></div>;

// export const HomeIntro = ({ children }: { children?: ReactNode }) => {
//   // const title = `SWIT Solutions`;
//   const title = `JoyTech - ג'ויטק`;
//   // const description = `כשהנדסה פוגשת חדשנות`;
//   const description = `Software & IT Solutions`;
//   const slogen = `Cuz We (En)Joy-Tech(nology)`;
//   const toolTip = 'לחצו כאן';

//   const ToolTipComponent = () => (
//     <div className="intro-link" onClick={() => console.log('onClick!!')}>
//       לחצו עלי לעוד פרטים מעניינים
//     </div>
//   );

//   return (
//     // <Tooltip
//     //   component={<ToolTipComponent />}
//     //   content={toolTip}
//     //   position={tooltipPostitionEnum.Up}
//     //   disabled={false}
//     // >
//     //   <div className="home-intro">{children}</div>
//     // </Tooltip>
//       <div className="home-intro">{children}</div>
//   );
// };
export const HomeIntro = ({ children }: { children?: ReactNode }) => {
  return <div className="home-intro">{children}</div>;
};
