import { PagingLayout } from 'ui-kit/paging/PagingLayout';
import { Card } from 'ui-kit/card';
import { useNavigate } from 'react-router';
import { imageHandler } from 'utils/ImageHandler';
import { Guide } from 'models/Guide';
import { useGuide } from 'hooks/useGuide';
import { useEffect } from 'react';

// const GuideItem = (item: Guide) => (
//   <tr
//     key={item.id}
//     onClick={() => {
//       console.log('Click: ', item.title);
//     }}
//   >
//     <td>{item.title}</td>
//   </tr>
// );

// const GuideItem = ({ guide, onClick }: { guide: Guide; onClick?: (guide: Guide) => void }) => (
//   <Card
//     onClick={() => {
//       onClick && onClick(guide);
//     }}
//     style={{
//       cursor: 'pointer',
//     }}
//   >
//     <h2>{guide.title}</h2>
//     <h3
//     style={{fontSize:25}}
//     >{guide.subject}</h3>

//     <img
//       alt="Guide Pic"
//       // style={{ alignSelf: 'center' }}
//       width={500}
//       height={250}
//       src={imageHandler('noImage')}
//     />

//     <div
//       style={{
//         // border: '1px solid black',
//         width:'80%',
//         fontSize:25
//       }}
//     >
//       <EllipsisText maxLength={200}>{guide.details}</EllipsisText>
//     </div>
//     {/* <div style={{ direction: 'rtl' }}>{guide.details}</div> */}
//     <div style={{}}>{new Date(guide.create_date).toLocaleDateString()}</div>
//   </Card>
// );

const GuideItem = ({ guide, onClick }: { guide: Guide; onClick?: (guide: Guide) => void }) => (
  <Card
    onClick={() => {
      onClick && onClick(guide);
    }}
    style={{
      cursor: 'pointer',
    }}
  >
    <h2>{guide.title}</h2>
      <img
        alt="Guide Pic"
        // style={{ alignSelf: 'center' }}
        width={500}
        height={250}
        src={imageHandler('noImage')}
      />
      <h3 style={{ fontSize: 25 }}>{guide.introduction}</h3>

    <div style={{}}>{new Date().toLocaleDateString()}</div>
  </Card>
);

// const Guides = () => {
//   const navigate = useNavigate();

//   return (
//     <PagingLayout
//       bar
//       title="מדריכים"
//       list={guides}
//       renderItem={(guide) =>
//         GuideItem({
//           guide,
//           onClick: (guide) => {
//             // console.log('Guide: ',guide);

//             navigate(`/guides/${guide.title}`, { state: { guide } });
//           },
//         })
//       }
//     />
//     // <div>
//     //   {/* <DropDown type="text" placeHolder="Search Guide" list={guides} /> */}
//     // </div>
//   );
// };




const Guides = () => {
  const navigate = useNavigate();

  const { guides } = useGuide();



 

  return (
    // <div>
    //   {JSON.stringify(guides)}
    // </div>
    <PagingLayout
      bar
      title="מדריכים"
      list={guides}
      renderItem={(guide) =>
        GuideItem({
          guide,
          onClick: (guide) => {
            // console.log('Guide: ',guide);
            navigate(`/guides/${guide.id}`, { state: { guide } });
          },
        })
      }
    />
    // <div>
    //   {/* <DropDown type="text" placeHolder="Search Guide" list={guides} /> */}
    // </div>
  );
};

export default Guides;
