import { useEffect, useState } from "react";

const articleList = [
    {
      title: 'How AI is Revolutionizing Cybersecurity',
      author: 'Alice Thompson',
      date: '2024-07-21',
      summary:
        'This article discusses the role of artificial intelligence in enhancing cybersecurity measures, focusing on threat detection and prevention.',
      url: 'https://www.techinsights.com/articles/ai-revolutionizing-cybersecurity',
    },
    {
      title: 'Decoding the Mysteries of Blockchain Technology',
      author: 'David Roberts',
      date: '2024-07-10',
      summary:
        'An in-depth look at blockchain technology, its applications beyond cryptocurrency, and its potential to disrupt various industries.',
      url: 'https://www.blockchainreview.com/articles/decoding-blockchain',
    },
    {
      title: 'The Future of Software Development: Low-Code Platforms',
      author: 'Maria Sanchez',
      date: '2024-06-25',
      summary:
        'Exploring the rise of low-code platforms in software development, enabling faster application delivery and reducing the need for extensive coding expertise.',
      url: 'https://www.devtrends.com/articles/future-of-software-development-low-code',
    },
    {
      title: 'Augmented Reality in Everyday Applications',
      author: 'James Nguyen',
      date: '2024-07-14',
      summary:
        'Examining the integration of augmented reality (AR) in daily life, from gaming and education to retail and healthcare.',
      url: 'https://www.arworld.com/articles/augmented-reality-everyday-applications',
    },
    {
      title: 'Navigating the Shift to Cloud-Native Architectures',
      author: 'Linda Parker',
      date: '2024-07-19',
      summary:
        'A guide to transitioning to cloud-native architectures, highlighting best practices, challenges, and the benefits of adopting a cloud-first approach.',
      url: 'https://www.cloudtech.com/articles/navigating-shift-to-cloud-native',
    },
  ];
  
  const InfiniteScrollComponent = () => {
    const [visibleArticles, setVisibleArticles] = useState<any[]>([]);
    const [page, setPage] = useState(1);
  
    useEffect(() => {
      const loadArticles = () => {
        const newArticles = articleList.slice(0, page * 2);
        setVisibleArticles(newArticles);
      };
  
      loadArticles();
    }, [page]);
  
    useEffect(() => {
      const handleScroll = () => {
        if (
          window.innerHeight + document.documentElement.scrollTop !==
          document.documentElement.offsetHeight
        )
          return;
        setPage((prevPage) => prevPage + 1);
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }, []);
  
    return (
      <div>
        <h1>Infinite Scroll Articles</h1>
        {visibleArticles.map((article, index) => (
          <div key={index} style={{ border: '1px solid #ccc', margin: '16px', padding: '16px' }}>
            <h2>{article.title}</h2>
            <p>
              <strong>Author:</strong> {article.author}
            </p>
            <p>
              <strong>Date:</strong> {article.date}
            </p>
            <p>{article.summary}</p>
            <a href={article.url} target="_blank" rel="noopener noreferrer">
              Read more
            </a>
          </div>
        ))}
        <div style={{ height: '100px', margin: '16px', textAlign: 'center' }}>
          {visibleArticles.length < articleList.length && <p>Loading...</p>}
        </div>
      </div>
    );
  };
  

  const Articles=()=>{

    return <div>

        <InfiniteScrollComponent/>
    </div>

  }


  export default Articles