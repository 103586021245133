
import { useCallback, useEffect, useState } from 'react';
import { Article } from 'models/Article';
import { httpRequst } from 'services/api';

export const useArticle = () => {
  const [articles, setArticles] = useState<Article[]>([]);
  const [selectedArticle, setSelectedArticle] = useState<Article>();


  const handleGetArticles = useCallback(async () => {
    let articleList = await httpRequst('GET','articles')
    setArticles(articleList)
  }, [setArticles]);

  const handleSelectedArticle = (article: Article) => {
    // console.log('Selected: ', article);
    setSelectedArticle((article) => article);
  };

  useEffect(() => {
   handleGetArticles()
  }, [handleGetArticles])

  return { handleGetArticles, articles, handleSelectedArticle, selectedArticle };
};
