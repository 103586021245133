const baseUrl = 'https://back.idanzadki.com/api'

export const apiUrl = ({
    guides: {
        getAll: '/guides',//GET
        addGuide: '/guides',//POST
        updateGuide: (gId: string | number) => `/guides/${gId}`,//PUT
        deleteGuide: (gId: string | number) => `/guides/${gId}`,//DELETE
    },
    articles: {
        getAll: '/articles',
        addArticle: '/articles',//POST
        updateArticle: (gId: string | number) => `/articles/${gId}`,//PUT
        deleteArticle: (gId: string | number) => `/articles/${gId}`,//DELETE
    },
    news: {
        getAll: '/news',
        addNews: '/news',//POST
        updateNews: (gId: string | number) => `/news/${gId}`,//PUT
        deleteNews: (gId: string | number) => `/news/${gId}`,//DELETE
    },
    professions: {
        getAll: '/professions',
        addNews: '/professions',//POST
        updateNews: (gId: string | number) => `/professions/${gId}`,//PUT
        deleteNews: (gId: string | number) => `/professions/${gId}`,//DELETE

    }

})

export type apiTypes = 'guides' | 'articles' | 'news' | 'fields'

export const ApiURL = (type: apiTypes) => ({
    getAll: `/${type}`,//GET
    createNew: `/${type}`,//POST
    update: (id: string | number) => `/${type}/${id}`,//PUT
    delete: (id: string | number) => `/${type}/${id}`,//DELETE
})