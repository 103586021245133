

const Forum = () => {
  return (
    <div
      style={{
        // display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >




    </div>
  );
};

export default Forum;
