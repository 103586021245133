import { useRef } from 'react';
import './style.css';

interface PopFromBottomProps {
  children: React.ReactNode;
  duration?: number;
  delay?: number;
}

export const PopFromBottom = ({ children, duration = 2, delay = 0 }: PopFromBottomProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const position = window.pageYOffset;
  const height = window.innerHeight;
  const offset = ref?.current ? ref.current.offsetHeight : 0;
  const offsety = ref?.current ? ref.current.offsetTop : 0;
  let size = offsety - height;
  let edge = position - size - offset;
  let pop = edge > 0;

  return (
    <div
      ref={ref}
      className={pop ? 'pop-from-bottom' : 'pop-from-bottom-before'}
      style={{ animationDelay: `${delay}s` }}
    >
      {children}
    </div>
  );
};
