import { useProject } from 'hooks/useProject';
import { IProject } from 'models/IProject';
import ProjectDetails from 'pages/project';
import './style.css';
import { InfiniteScrollLayout } from 'ui-kit/infiniteScroll/InfiniteScrollLayout';


const Projects = () => {
  const { handleSelectedProject, selectedProject } = useProject();

  const ProjectItem = <T extends IProject>(item: T | null) => (
    <div className="project-item" onClick={() => handleSelectedProject(item)}>
      <img alt="" src={item?.image} height={150} />
      <div>{item?.id}</div>
      <div>{item?.create_date}</div>
      <div>{item?.intro}</div>
      <div>{item?.hashTags?.length && `#${item?.hashTags}`}</div>
    </div>
  );

  return selectedProject ? (
    <div>
      <button onClick={() => handleSelectedProject(null)}>Back</button>
      <button onClick={() => {}}>Back</button>
      <ProjectDetails project={selectedProject} />
    </div>
  ) : (
    <div>
      {/* <Typing text='Hello kzjdhfskjfhsrdkighsdhghesoighseogishgosihgsoih'/> */}

      <div>פרויקטים</div>
      {/* <input style={{ width: '50px' }} type="text" value={query} onChange={handleChange} /> */}
      <InfiniteScrollLayout
        path="http://localhost:3001/api/projects"
        title="Projects"
        render={ProjectItem}
      />
    </div>
  );
};

export default Projects;
