import axios from 'axios';
import { useState, useCallback, useEffect, useMemo } from 'react';
import { ProjectService } from 'services/project';

interface LazyLoadingProps {
  page: number;
  query: string;
}

export const useLazyLoading = ({ page }: LazyLoadingProps) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<boolean>(false);
  const [lazyList, setList] = useState<any[]>([]);

  //   const getPage = useMemo(async () => {
  //     const res = await axios.get(`${query}?page=${page}`);
  //     return res.data
  //   }, [query,page]);

  const sendQuery = useCallback(async () => {
    
    try {
      setLoading(true);
      setError(false);
      setTimeout(async ()=>{
        const newPage = await ProjectService.getPage(page);
      setList((prev) => [...prev, ...newPage.data]);
      setLoading(false);
      return newPage;
      },100)
    } catch (err) {
      console.log('Error: ', err);
      setError(true);
    }
  }, [page]);

  useEffect(() => {
      
      const tick = setTimeout(() => {
        console.log('Lazy Page: ',page);
      page>0&&sendQuery();
    }, 0);
    return function cleanUp() {
      clearTimeout(tick);
    };
  }, [sendQuery, page]);

  return { loading, error, lazyList };
};
