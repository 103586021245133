
import { useCallback, useEffect, useState } from 'react';
import { httpRequst } from 'services/api';

export const useNews = () => {
    const [news, setNews] = useState<any[]>([]);

    const handleGetNews = useCallback(async () => {
        let guideList = await httpRequst('GET', 'news')
        setNews(guideList)
    }, [setNews]);


    useEffect(() => {
        handleGetNews()
    }, [handleGetNews])

    return { news };
};
