import classnames from 'classnames';
import uuid from 'react-uuid';
import { usePagination, usePaginationProps, DOTS } from 'ui-kit/hooks/usePagination';
import { PaginationItem } from './PaginationItem';
import './pagination.css';

interface PaginationProps extends usePaginationProps {
  onPageChange: (currentPage: number) => void;
  className?: string;
}

export const PaginationBar = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
  className,
}: PaginationProps) => {
  const paginationRange: string | number[] | any = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <ul 
    className={classnames('pagination-container', className)}
    >
      {/* Left navigation arrow */}
      <li
        className={classnames('pagination-item', {
          disabled: currentPage === 1,
        })}
        onClick={onPrevious}  
      >
        <div className="arrow-left" />
      </li>
      {paginationRange.map((pageNumber: number) => {
        // If the pageItem is a DOT, render the DOTS unicode character
        if (pageNumber === DOTS) {
          return (
            <li key={uuid()} className="pagination-item dots">
              &#8230;
            </li>
          );
        }
        
        // Render our Page Pills
        return (
          <PaginationItem
            key={uuid()}
            currentPage={currentPage}
            pageNumber={pageNumber}
            onPageChange={onPageChange}
          />
        );
      })}
      {/*  Right Navigation arrow */}
      <li
        className={classnames('pagination-item', {
          disabled: currentPage === lastPage,
        })}
        onClick={onNext}
      >
        {/* <div className="arrow right" /> */}
        <div className="arrow-right" />
      </li>
    </ul>
  );
};
