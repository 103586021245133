import { useLocation } from 'react-router';
import { Card } from 'ui-kit/card';
import { Guide } from 'models/Guide';
import { Section } from 'models/Section';
import { useEffect, useRef, useState } from 'react';
import { imageHandler } from 'utils/ImageHandler';
import './style.css';

const demo = {
  id: 'id2',
  title: 'React 19: שינוי חוקי המשחק לפיתוח אתרים מודרני',

  type: 'Software',
  introduction:
    'React, הספרייה הפופולרית ליצירת ממשקי משתמש ב-JavaScript, עומדת לעשות קפיצה ענקית קדימה עם גרסה 19 הקרובה שלה. עם התקרבות שחרור React 19, מפתחים ברחבי העולם נרגשים לגבי התכונות החדשות והשיפורים שמבטיחים לה революציון את הדרך שבו אנו בונים אפליקציות ווב.',
  sections: [
    {
      title: 'מה חדש ב-React 19?',
      subsections: [
        {
          title: 'תכונות חדשות',
          points: [
            'הוקים חדשים לניהול טפסים ועדכונים אופטימיים בממשק המשתמש',
            'שיפורי יכולות משיכת נתונים',
            'ניהול רפרנסים משופר',
            'אופטימיזציות ביצועים משמעותיות',
            'שיפור חווית המפתחים',
          ],
        },
      ],
      code: 'code',
    },
  ],
  example: {
    title: '',
    code: '',
    explanation: '',
  },
  conclusion:
    'בעודנו מחכים בקוצר רוח לשחרור הרשמי, עכשיו הזמן המושלם להתחיל להתנסות בתכונות החדשות הללו בפרויקטים שלך. על ידי היכרות עם יכולות React 19, תהיה מוכן לנצל את מלוא הפוטנציאל שלה כאשר היא תושק.',
  created_at: '06-24-2024',
};

// Component to render each section
const SectionComponent: React.FC<{ section: Section }> = ({ section }) => (
  <div>
    <h2>{section.title}</h2>

    {section.code && (
      <pre
        style={{
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-word',
          backgroundColor: '#1e1e1e',
          padding: '15px',
          borderRadius: '5px',
          marginBottom: '15px',
          fontFamily: 'monospace',
          overflowX: 'auto',
          direction: 'ltr',
        }}
      >
        {section.code.content}
      </pre>
    )}

    {section.content && (
      <Card
        style={{
          backgroundColor: '#121212',
          // alignSelf: 'center'
        }}
      >
        <p>{section.content}</p>
        {/* {section.duration && (
          <p>
            <strong>Duration:</strong> {section.duration}
          </p>
        )} */}
      </Card>
    )}
    {section.subsections &&
      section.subsections.map((sub, index) => (
        <div key={index} style={{ marginLeft: '20px', gap: 15 }}>
          <h3>{sub.title}</h3>
          {sub.content && <p>{sub.content}</p>}
          {sub.points && (
            <ul>
              {sub.points.map((point, idx) => (
                <li key={idx}>{point}</li>
              ))}
            </ul>
          )}
          {sub.code && (
            <pre
              style={{
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-word',
                backgroundColor: '#1e1e1e',
                padding: '15px',
                borderRadius: '5px',
                marginBottom: '15px',
                fontFamily: 'monospace',
                overflowX: 'auto',
                direction: 'ltr',
              }}
            >
              {sub.code.content}
            </pre>
          )}
        </div>
      ))}
    {section.points && (
      <ul>
        {section.points.map((point, index) => (
          <li key={index}>{point}</li>
        ))}
      </ul>
    )}
  </div>
);

// // Main component to render the guide
// const Guide = ({ guide }: { guide: { title: string; sections: Section[] } }) => (
//   <div
//     style={{
//       display: 'flex',
//       flexDirection: 'column',
//       // alignItems: 'center',
//       // alignItems:'start',
//       gap: 30,
//       height: '100%',
//     }}
//   >
//     <h1>{guide.title}</h1>
//     {guide.sections.map((section, index) => (
//       <SectionComponent key={index} section={section} />
//     ))}
//   </div>
// );

// const GuidePage = () => {

//   const location = useLocation();
//   const {
//     guide,
//   }: {
//     guide: Guide
//   } = location.state || null;

//   return (
//     <div
//       style={{
//         color: 'white',
//         backgroundColor: '#23272f',
//         display: 'flex',

//         height: '100%',
//         overflow: 'hidden',
//         // overflow: 'scroll',
//       }}
//     >
//       <div
//         style={{
//           height: '100%',
//           width: '20%',
//           // border: '1px solid blue',
//           bottom: 0,
//         }}
//       />
//       <div
//         style={{
//           display: 'flex',
//           overflow: 'scroll',
//           scrollbarWidth: 'none',
//           // border: '1px solid white',
//           // alignItems: 'center',
//           justifyContent: 'center',
//         }}
//       >
//         <div
//           style={{
//             display: 'flex',
//             flexDirection: 'column',
//             // alignItems: 'center',
//             // alignItems:'start',
//             gap: 30,
//             height: '100%',
//           }}
//         >
//           <h1>{guide.title}</h1>
//           {guide.sections.map((section, index) => (
//             <SectionComponent key={index} section={section} />
//           ))}
//           {
//             guide.example&&<div>
//               {
//                 guide.example.code
//               }
//             </div>
//           }
//         </div>
//       </div>
//       <div
//         style={{
//           // position: 'relative',
//           // position:'absolute',
//           height: '100%',
//           width: '20%',
//           // border: '1px solid blue',
//           top: 0,
//           bottom: 0,
//           right: 0,
//         }}
//       />
//     </div>
//   );
// };

// const GuidePage = () => {
//   const location = useLocation();
//   const { guide }: { guide: Guide } = location.state || { guide: { sections: [], example: {} } };

//   return (
//     <div
//       style={{
//         color: 'white',
//         backgroundColor: '#23272f',
//         display: 'flex',
//         height: '100vh', // Full viewport height
//         overflow: 'hidden',
//         fontFamily: 'Arial, sans-serif', // Improved font for readability
//       }}
//     >
//       <aside
//         style={{
//           width: '20%',
//           backgroundColor: '#2c2f36', // Slightly different color for sidebar
//           padding: '20px',
//           boxSizing: 'border-box',
//           borderRight: '1px solid #343a40', // Divider line
//           display: 'flex',
//           flexDirection: 'column',
//           gap: '20px',
//         }}
//       >
//         <h2>Left Sidebar</h2>
//         {/* Add any sidebar content or navigation items */}
//       </aside>

//       <main
//         style={{
//           flex: 1,
//           overflowY: 'auto',
//           padding: '20px',
//           boxSizing: 'border-box',
//         }}
//       >
//         <h1 style={{ marginBottom: '20px' }}>{guide.title}</h1>
//         {guide.sections.map((section, index) => (
//           <SectionComponent key={index} section={section} />
//         ))}
//         {guide.example && (
//           <div
//             style={{
//               marginTop: '30px',
//               padding: '20px',
//               backgroundColor: '#2c2f36',
//               borderRadius: '8px',
//             }}
//           >
//             {guide.example.title && <h2 style={{ marginTop: '0' }}>{guide.example.title}</h2>}
//             <pre
//               style={{
//                 whiteSpace: 'pre-wrap',
//                 wordBreak: 'break-word',
//                 backgroundColor: '#1e1e1e',
//                 padding: '15px',
//                 borderRadius: '5px',
//                 marginBottom: '15px',
//                 fontFamily: 'monospace', // Better font for code
//                 overflowX: 'auto', // Handle long lines
//               }}
//             >
//               {guide.example.code}
//             </pre>
//             {guide.example.explanation && (
//               <p style={{ margin: '0', fontSize: '16px', lineHeight: '1.5' }}>
//                 {guide.example.explanation}
//               </p>
//             )}
//           </div>
//         )}
//       </main>

//       <aside
//         style={{
//           width: '20%',
//           backgroundColor: '#2c2f36',
//           padding: '20px',
//           boxSizing: 'border-box',
//           borderLeft: '1px solid #343a40', // Divider line
//           display: 'flex',
//           flexDirection: 'column',
//           gap: '20px',
//         }}
//       >
//         <h2>Right Sidebar</h2>
//         {/* Add any sidebar content or additional elements */}
//       </aside>
//     </div>
//   );
// };

// const GuidePage = () => {
//   const location = useLocation();
//   const { guide }: { guide: Guide } = location.state || { guide: { sections: [], example: {} } };

//   const [activeIndex, setActiveIndex] = useState<number | null>(null);
//   const sectionRefs = useRef<HTMLDivElement[]>([]);
//   const observer = useRef<IntersectionObserver | null>(null);

//   useEffect(() => {
//     // Initialize IntersectionObserver
//     observer.current = new IntersectionObserver((entries) => {
//       entries.forEach((entry) => {
//         const index = sectionRefs.current.indexOf(entry.target as HTMLDivElement);
//         if (entry.isIntersecting) {
//           setActiveIndex(index);
//         }
//       });
//     }, { threshold: 0.5 }); // Adjust threshold as needed

//     // Observe each section
//     sectionRefs.current.forEach((section) => {
//       if (section) {
//         observer.current?.observe(section);
//       }
//     });

//     // Cleanup observer on component unmount
//     return () => {
//       observer.current?.disconnect();
//     };
//   }, []);

//   return (
//     <div
//       style={{
//         color: 'white',
//         backgroundColor: '#23272f',
//         display: 'flex',
//         height: '100vh',
//         overflow: 'hidden',
//         fontFamily: 'Arial, sans-serif',
//       }}
//     >
//       <aside
//         style={{
//           width: '20%',
//           backgroundColor: '#2c2f36',
//           padding: '20px',
//           boxSizing: 'border-box',
//           borderRight: '1px solid #343a40',
//           position: 'fixed',
//           height: '100vh',
//           overflowY: 'auto', // Allow scrolling in sidebar
//         }}
//       >
//         <h2 style={{ marginTop: '0' }}>Guide Sections</h2>
//         <ul style={{ listStyle: 'none', padding: '0', margin: '0' }}>
//           {guide.sections.map((section, index) => (
//             <li
//               key={index}
//               className={`sidebar-item ${activeIndex === index ? 'highlight' : ''}`}
//               style={{
//                 padding: '10px',
//                 borderRadius: '5px',
//                 transition: 'background-color 0.3s',
//                 cursor: 'pointer',
//               }}
//               onClick={() => {
//                 if (sectionRefs.current[index]) {
//                   sectionRefs.current[index].scrollIntoView({ behavior: 'smooth' });
//                 }
//               }}
//             >
//               {section.title}
//             </li>
//           ))}
//         </ul>
//       </aside>

//       <main
//         style={{
//           flex: 1,
//           marginLeft: '20%', // Account for fixed sidebar width
//           padding: '20px',
//           boxSizing: 'border-box',
//           overflowY: 'auto', // Allow scrolling in main content
//           height: '100vh', // Full viewport height
//         }}
//       >
//         <header
//           style={{
//             marginBottom: '20px',
//             padding: '10px',
//             backgroundColor: '#2c2f36',
//             borderRadius: '8px',
//           }}
//         >
//           <h1 style={{ margin: '0' }}>{guide.title}</h1>
//           <p style={{ margin: '10px 0', fontSize: '16px', color: '#b0b0b0' }}>
//             <strong>Type:</strong> {guide.type}
//           </p>
//           <p style={{ margin: '0', fontSize: '16px', color: '#b0b0b0' }}>
//             <strong>Date:</strong> {new Date(guide.created_at).toLocaleDateString()}
//           </p>
//         </header>

//         {guide.sections.map((section, index) => (
//           <div
//             key={index}
//             ref={(el) => (sectionRefs.current[index] = el as HTMLDivElement)}
//             style={{ marginBottom: '40px' }} // Add space between sections
//           >
//             <SectionComponent section={section} />
//           </div>
//         ))}
//         {guide.example.title!=='' && (
//           <div
//             style={{
//               marginTop: '30px',
//               padding: '20px',
//               backgroundColor: '#2c2f36',
//               borderRadius: '8px',
//             }}
//           >
//             {guide.example.title && <h2 style={{ marginTop: '0' }}>{guide.example.title}</h2>}
//             <pre
//               style={{
//                 whiteSpace: 'pre-wrap',
//                 wordBreak: 'break-word',
//                 backgroundColor: '#1e1e1e',
//                 padding: '15px',
//                 borderRadius: '5px',
//                 marginBottom: '15px',
//                 fontFamily: 'monospace',
//                 overflowX: 'auto',
//               }}
//             >
//               {guide.example.code}
//             </pre>
//             {guide.example.explanation && (
//               <p style={{ margin: '0', fontSize: '16px', lineHeight: '1.5' }}>
//                 {guide.example.explanation}
//               </p>
//             )}
//           </div>
//         )}
//       </main>

//       <aside
//         style={{
//           width: '20%',
//           backgroundColor: '#2c2f36',
//           padding: '20px',
//           boxSizing: 'border-box',
//           borderLeft: '1px solid #343a40',
//           position: 'relative',
//           height: '100vh', // Full viewport height
//         }}
//       >
//         <h2 style={{ marginTop: '0' }}>Additional Info</h2>
//         {/* Add any sidebar content or additional elements */}
//       </aside>
//     </div>
//   );
// };

const GuidePage = () => {
  const location = useLocation();
  const { guide }: { guide: Guide } = location.state || { guide: { sections: [], example: {} } };

  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const sectionRefs = useRef<HTMLDivElement[]>([]);
  const observer = useRef<IntersectionObserver | null>(null);
  // const direction=guide.title.includes('א')||guide.title.includes('ה')||guide.title.includes('ו')||guide.title.includes('י')?'rtl':'ltr'
  const hebrewRegex = /[\u0590-\u05FF]/;
  const direction = hebrewRegex.test(guide.title) ? 'rtl' : 'ltr';

  useEffect(() => {
    // Initialize IntersectionObserver
    observer.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const index = sectionRefs.current.indexOf(entry.target as HTMLDivElement);
          if (entry.isIntersecting) {
            setActiveIndex(index);
          }
        });
      },
      { threshold: 0.5 },
    ); // Adjust threshold as needed

    // Observe each section
    sectionRefs.current.forEach((section) => {
      if (section) {
        observer.current?.observe(section);
      }
    });

    // Cleanup observer on component unmount
    return () => {
      observer.current?.disconnect();
    };
  }, []);

  return (
    <div
      style={{
        color: 'white',
        backgroundColor: '#23272f',
        fontFamily: 'Arial, sans-serif',
        direction: direction,
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      <aside
        className="side"
        style={{
          width: '20%',
          backgroundColor: '#2c2f36',
          padding: '20px',
          boxSizing: 'border-box',
          borderRight: '1px solid #343a40',
          position: 'fixed',
          height: '100vh',
          overflowY: 'auto', // Allow scrolling in sidebar
        }}
      >
        <h2 style={{ marginTop: '0' }}>Guide Sections</h2>
        <ul style={{ listStyle: 'none', padding: '0', margin: '0' }}>
          {guide.sections.map((section, index) => (
            <li
              key={index}
              className={`sidebar-item ${activeIndex === index ? 'highlight' : ''}`}
              style={{
                padding: '10px',
                borderRadius: '5px',
                transition: 'background-color 0.3s',
                cursor: 'pointer',
              }}
              onClick={() => {
                if (sectionRefs.current[index]) {
                  sectionRefs.current[index].scrollIntoView({ behavior: 'smooth' });
                }
              }}
            >
              {section.title}
            </li>
          ))}
        </ul>
      </aside>

      <main
        className="main"
        style={{
          // marginInlineStart: '20%', // Account for fixed sidebar width
          padding: '20px',
          boxSizing: 'border-box',
          overflowY: 'auto', // Allow scrolling in main content
          height: '100vh', // Full viewport height
          // height: '100%',

          scrollbarWidth: 'none',
        }}
      >
        <header
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '20px',
            padding: '10px',
            backgroundColor: '#2c2f36',
            borderRadius: '8px',
            flexDirection: 'column',
          }}
        >
          <h1 style={{ margin: '0' }}>{guide.title}</h1>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 15,
              width: '100%',
              alignSelf: 'flex-start',
            }}
          >
            <p style={{ margin: '0', fontSize: '16px', color: '#b0b0b0' }}>
              <strong>Date:</strong> {new Date(guide.created_at).toLocaleDateString()}
            </p>
            <p style={{ margin: '10px 0', fontSize: '16px', color: '#b0b0b0' }}>
              <strong>Type:</strong> {guide.type}
            </p>
          </div>
          <Card>

          <img
            style={{
              width: '100%',
              // alignSelf: 'center',
              height: '100%',
            }}
            src={guide.image || imageHandler('noImage')}
            alt=""
            // height={200}
          />
          </Card>
        </header>
        <Card style={{ color: 'black' }}>{guide.introduction}</Card>
        {guide.sections.map((section, index) => (
          <div
            key={index}
            ref={(el) => (sectionRefs.current[index] = el as HTMLDivElement)}
            style={{ marginBottom: '40px' }} // Add space between sections
          >
            <SectionComponent section={section} />
          </div>
        ))}

        {guide.example && (
          <div
            style={{
              marginTop: '30px',
              padding: '20px',
              backgroundColor: '#2c2f36',
              borderRadius: '8px',
            }}
          >
            {guide.example.title && <h2 style={{ marginTop: '0' }}>{guide.example.title}</h2>}
            <pre
              style={{
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-word',
                backgroundColor: '#1e1e1e',
                padding: '15px',
                borderRadius: '5px',
                marginBottom: '15px',
                fontFamily: 'monospace',
                overflowX: 'auto',
                direction: 'ltr',
              }}
            >
              {guide.example.code}
            </pre>
            {guide.example.explanation && (
              <p style={{ margin: '0', fontSize: '16px', lineHeight: '1.5' }}>
                {guide.example.explanation}
              </p>
            )}
          </div>
        )}
      </main>
      {/* <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div>Main</div>
      </div> */}

      {/* <aside
      className='side'
        style={{
          width: '20%',
          backgroundColor: '#2c2f36',
          padding: '20px',
          boxSizing: 'border-box',
          borderLeft: '1px solid #343a40',
          position: 'relative',
          // height: '100vh', // Full viewport height
        }}
      >
        <h2 style={{ marginTop: '0' }}>Additional Info</h2>
+      </aside> */}
    </div>
  );
};

export default GuidePage;
