import axios from 'axios';
import { apiTypes, ApiURL } from 'utils/url';

const baseURL = 'https://back.idanzadki.com/api';
// const baseURL = 'http://localhost:3001/api';
  
export const Api = axios.create({
  baseURL,
});


export const httpRequst = async (method: 'GET' | 'POST' | 'PUT' | 'DELETE', type: apiTypes, payload?: any) => {

  let res = null

  switch (method) {
    case 'POST':
      res = await Api.post(ApiURL(type).createNew, payload)
      break
    case 'GET':
      res = await Api.get(ApiURL(type).getAll)
      break
    case 'PUT':
      res = await Api.put(ApiURL(type).update(payload.id), payload)
      break
    case 'DELETE':
      res = await Api.delete(ApiURL(type).delete(payload.id))
      break
  }

  return res && res.data

  // const res = await Api.get(ApiURL('guides').getAll)
}
