import './style.css';

export const SectionWrapper = ({
  children,
  horizontal,
  background,
}: {
  children: React.ReactNode;
  horizontal?: boolean;
  background?: string;
}) => (
  <div
    style={{
      display: 'flex',
      // background: background || '#23272f',
      background: background || 'inherit',
      flexDirection: horizontal ? 'row' : 'column',
      // color:'white',
      padding:15
    }}
  >
    {children || ''}
  </div>
);
