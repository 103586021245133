// import { useLocation } from 'react-router';

// // const services = {
// //   תוכנה: {
// //     type: 'Software',
// //     professions: [
// //       {
// //         title: `אפיוני מערכת עבור פרויקטים בתחום התכונה והמחשוב`,
// //         dots: [],
// //       },
// //       {
// //         title: 'פיתוח עמודי נחיתה',
// //         dots: ['Javascript', 'TypeScript', 'React JS/TS', 'WordPress'],
// //       },
// //       {
// //         title: 'פיתוח אפליקציות',
// //         dots: ['Javascript', 'TypeScript', 'React Native, Android', 'React Ionic'],
// //       },
// //       {
// //         title: 'פיתוח שרתים',
// //         dots: ['Node JS/TS', 'Python'],
// //       },
// //       {
// //         title: 'בסיסי נתונים',
// //         dots: ['MongoDB', 'PostgreSQL', 'MySQL', 'SQLite'],
// //       },
// //     ],
// //   },
// //   חומרה: {
// //     type: 'Hardware',
// //     professions: [

// //     ],
// //   },
// //   מחשבים: {
// //     type: 'Computing',
// //     professions: [

// //     ],
// //   },
// //   אינטגרציה: {
// //     type: 'Integration',
// //     professions: [

// //     ],
// //   },
// //   IOT: {
// //     type: 'IOT',
// //     professions: [

// //     ],
// //   },
// //   IT: {
// //     type: 'IT',
// //     professions: [

// //     ],
// //   },
// // };

// const services = {
//   תוכנה: {
//     type: 'Software',
//     professions: [
//       {
//         title: 'אפיוני מערכת עבור פרויקטים בתחום התכונה והמחשוב',
//         dots: [],
//       },
//       {
//         title: 'פיתוח עמודי נחיתה',
//         dots: ['Javascript', 'TypeScript', 'React JS/TS', 'WordPress'],
//       },
//       {
//         title: 'פיתוח אפליקציות',
//         dots: ['Javascript', 'TypeScript', 'React Native', 'Android', 'React Ionic'],
//       },
//       {
//         title: 'פיתוח שרתים',
//         dots: ['Node JS/TS', 'Python'],
//       },
//       {
//         title: 'בסיסי נתונים',
//         dots: ['MongoDB', 'PostgreSQL', 'MySQL', 'SQLite'],
//       },
//     ],
//   },
//   חומרה: {
//     type: 'Hardware',
//     professions: [
//       {
//         title: 'תכנון מעגלים אלקטרוניים',
//         dots: ['Altium Designer', 'OrCAD', 'Eagle'],
//       },
//       {
//         title: 'פיתוח קושחה',
//         dots: ['C', 'C++', 'Assembly'],
//       },
//       {
//         title: 'בדיקות חומרה',
//         dots: ['Oscilloscope', 'Logic Analyzer'],
//       },
//     ],
//   },
//   מחשבים: {
//     type: 'Computing',
//     professions: [
//       {
//         title: 'ניהול מערכות',
//         dots: ['Windows Server', 'Linux', 'Active Directory'],
//       },
//       {
//         title: 'תחזוקת חומרה',
//         dots: ['Hardware Troubleshooting', 'PC Building', 'Peripheral Installation'],
//       },
//       {
//         title: 'תמיכה טכנית',
//         dots: ['Help Desk Support', 'Remote Assistance', 'Customer Service'],
//       },
//     ],
//   },
//   אינטגרציה: {
//     type: 'Integration',
//     professions: [
//       {
//         title: 'אינטגרציית מערכות',
//         dots: ['API Integration', 'Microservices', 'Enterprise Service Bus (ESB)'],
//       },
//       {
//         title: 'ניהול פרויקטים',
//         dots: ['Project Planning', 'Agile Methodologies', 'Scrum'],
//       },
//       {
//         title: 'בדיקות אינטגרציה',
//         dots: ['Integration Testing', 'End-to-End Testing'],
//       },
//     ],
//   },
//   IOT: {
//     type: 'IOT',
//     professions: [
//       {
//         title: 'פיתוח מערכות IOT',
//         dots: ['Arduino', 'Raspberry Pi', 'ESP32'],
//       },
//       {
//         title: 'ניהול נתונים',
//         dots: ['MQTT', 'CoAP', 'HTTP'],
//       },
//       {
//         title: 'אבטחת IOT',
//         dots: ['Encryption', 'Network Security', 'IoT Security Protocols'],
//       },
//     ],
//   },
//   IT: {
//     type: 'IT',
//     professions: [
//       {
//         title: 'תשתיות IT',
//         dots: ['Networking', 'Server Management', 'Cloud Infrastructure'],
//       },
//       {
//         title: 'אבטחת מידע',
//         dots: ['Cybersecurity', 'Firewalls', 'Intrusion Detection'],
//       },
//       {
//         title: 'שירותי ענן',
//         dots: ['AWS', 'Azure', 'Google Cloud'],
//       },
//     ],
//   },
// };

// const ServicePage = () => {
//   const location = useLocation();
//   const {
//     service,
//   }: {
//     service: keyof typeof services;
//   } = location.state || null;

//   const ob = services[service];

//   return (
//     <div
//       style={{
//         // display: 'flex',
//         flexDirection: 'column',
//         justifyContent: 'center',
//         alignItems: 'center',
//       }}
//     >
//       <h1>{service}</h1>
//       {ob.professions.map((prof) => (
//         <div>
//           <h2>{prof.title}</h2>
//           {prof.dots.map((dot) => (
//             <div
//               style={{
//                 textAlign: 'center',
//               }}
//             >
//               {dot}
//             </div>
//           ))}
//         </div>
//       ))}
//     </div>
//   );
// };

// export default ServicePage;

import { useLocation } from 'react-router';
import { Card } from 'ui-kit/card';

const ServicePage = () => {
  const location = useLocation();
  const {
    service,
  }: {
    service: any;
  } = location.state || null;

  return (
    <div
      style={{
        // display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <h1>{service.type}</h1>
      {/* <img alt="" src={service.image} style={{ height: 300, alignSelf: 'center' }} /> */}
      <Card>

      {service.professions.map((prof: any) => (
        <div>
          <h2>{prof.title}</h2>
          {prof.dots.map((dot: any) => (
            <div
              style={{
                textAlign: 'center',
              }}
            >
              {dot}
            </div>
          ))}
        </div>
      ))}
      </Card>
    </div>
  );
};

export default ServicePage;
