import { useEffect, useRef } from 'react';
import { tooltipPostitionEnum } from 'models/IToolTip';
import Tooltip from 'ui-kit/toolTip';
import './style.css';

const scrollToTop = () => {
  // console.log('Scroll: ');

  window.scrollTo({
    behavior: 'smooth',
    top: 0,
  });
};

export const ScrollUpButton = () => {
  const ref = useRef<HTMLDivElement | null>(null);
  const position = window.pageYOffset;
  const height = window.innerHeight;
  const offset = ref?.current ? ref.current.offsetHeight : 0;
  const offsety = ref?.current ? ref.current.offsetTop : 0;
  let size = offsety - height;
  let edge = position - size - offset;
  let pop = edge >= 1500;


  return (
    <div className="scroll-up-button">
      {pop && (
        // <Tooltip content="Scroll Up" position={tooltipPostitionEnum.Down} disabled={false}>
          <img
            alt={'Scroll Up'}
            className="scroll-up-button"
            onClick={scrollToTop}
            src={
              'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fmedia3.giphy.com%2Fmedia%2FMEuyrVS3UMe7DHm4Rq%2Fsource.gif&f=1&nofb=1&ipt=53f424576a5bcbb29f6d3e5cf6386f1ac47d358d5837b6e377c7f6d812050e9e&ipo=images'
            }
            height={60}
          />
        // </Tooltip>
      )}
    </div>
  );
};
