import { useEffect, useState } from 'react';
import { useProject } from 'hooks/useProject';
import { Guide } from 'models/IGuide';
import { IProject } from 'models/IProject';
import './style.css';
import { proj } from 'consts/data';
interface ProjectProps {
  project?: IProject | null|undefined;
}

const ProjectDetails = ({project}:ProjectProps) => {
  const [text,setText]=useState([ <textarea name="postContent" value={'Code'} 
  className="code-snippet" />
  // const [text,setText]=useState([{component:  <textarea name="postContent" value={'Code'} className="code-snippet" />
])

  useEffect(() => {
    console.log('selected from: : ', project);
  }, [project]);

  let {
    id,
    title,
    subject,
    intro,
    details,
    image,
    content,
    create_date,
    update_date,
    hashTags,
    codeList = [],
  } =project||{}

  return (
    <div className="project">
      <h2>{title}</h2>
      <img
        alt={''}
        src={image}
        style={{
          height: '300px',
          borderRadius: '15px',
        }}
      />
      <h4>created: {create_date?new Date(create_date).toString():''}</h4>
      <h3>{subject}</h3>
      <h3>{'Intro'}</h3>
      <div className="intro">{intro}</div>
      <h3>{'Details'}</h3>
      {/* <textarea className='details' value={details}/> */}
      <div className="details"> {
      
      details
      }</div>
      {/* <div onClick={()=>setText(prev=>[...prev,{component:<textarea name="postContent" value={codeList[codeList.length-1]} className="code-snippet" /> */}
      <div onClick={()=>setText(prev=>[...prev,<textarea name="postContent"
       value={codeList[codeList.length-1]} className="code-snippet" />
])}>
        Add
      </div>
      {codeList?.length > 0 && (
        <div style={{display:'flex',flexDirection:'column'}}>
          {/* <textarea name="postContent" value={codeList[0]} className="code-snippet" /> */}
        {text.map(c=>c)}
        </div>
      )}
    </div>
  );
};

export default ProjectDetails;
