import Guides from 'pages/guides';
import JoyTech from 'pages/joytech';
import ProjectDetails from 'pages/project';
import Projects from 'pages/projects';
import { BrowserRouter, Link, Route, Routes, useLocation } from 'react-router-dom';

import AddNewGuide from 'pages/addNewGuide';
import Forum from 'pages/forum';
import {
  JSXElementConstructor,
  Key,
  ReactElement,
  ReactFragment,
  ReactPortal,
  useEffect,
} from 'react';
import Articles from 'pages/articles';
import GuidePage from 'pages/guide';
import SoftwarePage from 'pages/software';
import ServicePage from 'pages/service';
import { Layout } from './layout';
import { Card } from './card';
import Home from 'pages/home';

// const Article = () => {
//   // const {article,state}=useParams()
//   const location = useLocation();
//   const { article } = location.state || {};

//   return article ? (
//     <div
//       style={{
//         width: '70%',
//         marginBottom: '3%',
//         alignSelf: 'center',
//       }}
//     >
//       {/* <h1>{article.title}</h1> */}
//       <h1 style={{ fontSize: 40 }}>{article.title}</h1>

//       {article.sections.map((section: any) => (
//         <div style={{ direction: 'rtl' }}>
//           {/* {JSON.stringify(section)} */}
//           <h2>{section.title || section.heading}</h2>
//           <div
//             style={{
//               alignItems: 'start',
//               direction: 'inherit',
//               fontSize: 22,
//             }}
//           >
//             {section.content || section.subSection}
//           </div>
//         </div>
//       ))}
//     </div>
//   ) : (
//     <div>No Article</div>
//   );
// };

// const Article = () => {
//   const location = useLocation();
//   const { article } = location.state || {};

//   const direction=article.title.includes('א')?'rtl':'ltr'

//   return article ? (
//     <div
//       style={{
//         height: '100%',
//         margin: '0 auto',
//         padding: '20px',
//         fontFamily: 'Arial, sans-serif',
//         direction
//       }}
//     >
//       <p style={{ fontSize: '1.2em', color: '#666' }}>{`${article.type}`}</p>
//       <h1 style={{ fontSize: '2.5em', marginBottom: '20px' }}>{article.title}</h1>
//       <p style={{ fontSize: '1.2em', color: '#666' }}>{` ${article.created_at}`}</p>
//       {article.sections.map((section:any, index:number) => (
//         <div key={index} style={{ marginBottom: '20px' }}>
//           <h2 style={{ fontSize: '2em', borderBottom: '2px solid #ddd', paddingBottom: '10px' }}>
//             {section.title}
//           </h2>
//           {section.content && (
//             <div
//               style={{
//                 fontSize: '1.2em',
//                 lineHeight: '1.6',
//                 color: '#333',
//               }}
//             >
//               {section.content}
//             </div>
//           )}
//           {section.subsections && section.subsections.map((subsection:any, subIndex:number) => (
//             <div key={subIndex} style={{ marginTop: '20px' }}>
//               <h3 style={{ fontSize: '1.8em', borderBottom: '1px solid #ddd', paddingBottom: '5px' }}>
//                 {subsection.title}
//               </h3>
//               {subsection.content && (
//                 <ul style={{ fontSize: '1.2em', lineHeight: '1.6', color: '#333', paddingLeft: '20px' }}>
//                   {subsection.content.map((item:any, itemIndex:number) => (
//                     <li key={itemIndex}>{item}</li>
//                   ))}
//                 </ul>
//               )}
//             </div>
//           ))}
//         </div>
//       ))}
//     </div>
//   ) : (
//     <div style={{ textAlign: 'center', marginTop: '50px', fontSize: '1.5em' }}>No Article</div>
//   );
// };

const Article = () => {
  const location = useLocation();
  const { article } = location.state || {};

  // const direction = article?.title.includes('א')||article?.title.includes('ה')||article?.title.includes('ו') ? 'rtl' : 'ltr';

  const hebrewRegex = /[\u0590-\u05FF]/;
  const direction = hebrewRegex.test(article.title) ? 'rtl' : 'ltr';

  const containerStyle = {
    height: '100%',
    margin: '0 auto',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    direction,
    maxWidth: '900px',
  };

  const metaInfoStyle = {
    fontSize: '1.1em',
    color: '#555',
    marginBottom: '10px',
  };

  const titleStyle = {
    fontSize: '2.4em',
    marginBottom: '15px',
    color: '#222',
  };

  const dateInfoStyle = {
    fontSize: '1.1em',
    color: '#777',
    marginBottom: '20px',
  };

  const sectionStyle = {
    marginBottom: '25px',
  };

  const sectionTitleStyle = {
    fontSize: '2.2em',
    borderBottom: '2px solid #ddd',
    paddingBottom: '10px',
    color: '#111',
  };

  const contentStyle = {
    fontSize: '1.1em',
    lineHeight: '1.8',
    color: '#333',
  };

  const subsectionStyle = {
    marginTop: '20px',
  };

  const subsectionTitleStyle = {
    fontSize: '1.8em',
    borderBottom: '1px solid #ddd',
    paddingBottom: '5px',
    color: '#222',
  };

  const listStyle = {
    fontSize: '1.1em',
    lineHeight: '1.8',
    color: '#333',
    paddingLeft: '20px',
  };

  const listItemStyle = {
    marginBottom: '10px',
  };

  const noArticleStyle = {
    textAlign: 'center',
    marginTop: '50px',
    fontSize: '1.5em',
    color: '#888',
  };

  return article ? (
    <div
      style={{
        margin: '0 auto',
        padding: '20px',
        fontFamily: 'Arial, sans-serif',
        direction,
        height: '100vh',
        maxWidth: '900px',
        overflow: 'scroll',
        scrollbarWidth: 'none',
        // backgroundColor: '#f9f9f9',
        // backgroundColor: '#e0f2f1',
        backgroundColor: '#f4f4f4',
        // backgroundColor: '#333333',
        // backgroundColor:'black'
      }}
    >
      <p style={metaInfoStyle}>{article.type}</p>
      <h1 style={titleStyle}>{article.title}</h1>
      <p style={dateInfoStyle}>{article.created_at}</p>

      {article.image && (
        <Card style={{ backgroundColor: 'white' }}>
          <img style={{ height: '100%', width: '100%' }} src={article.image} alt="Arduino" />
        </Card>
      )}
      <Card
      style={{margin:10}}
      >
        {article.introduction && <div style={{ fontSize: 20 }}>{article.introduction}</div>}
      </Card>
      {article.sections.map(
        (
          section: {
            title: string;

            content: string | string[];

            subsections: any[];
          },
          index: Key | null | undefined,
        ) => (
          <div key={index} style={sectionStyle}>
            <h2 style={sectionTitleStyle}>{section.title}</h2>
            {section.content && <div style={contentStyle}>{section.content}</div>}
            {section.subsections &&
              section.subsections.map((subsection, subIndex) => (
                <div key={subIndex} style={subsectionStyle}>
                  <h3 style={subsectionTitleStyle}>{subsection.title}</h3>
                  {subsection.content && (
                    <ul style={listStyle}>
                      {subsection.content.map((item: any, itemIndex: number) => (
                        <li key={itemIndex} style={listItemStyle}>
                          {item}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
          </div>
        ),
      )}
      {article.conclution && (
        <div style={sectionStyle}>
          <h2>Conclution</h2>
          <div style={{ fontSize: 18 }}>{article.conclution}</div>
        </div>
      )}

      {article.sources && (
        <div
        // style={{border:'1px solid black'}}
        >
          <h2>{'מקורות'}</h2>
          {article.sources.map((s: string) => (
            <div style={{ direction: 'ltr', display: 'flex', gap: 5 }}>
              <div>{s.split(': ')[0]}:</div>
              <Link to={s.split(': ')[1]}>{s.split(': ')[1]}</Link>
            </div>
          ))}
        </div>
      )}
    </div>
  ) : (
    <div style={{ textAlign: 'center', marginTop: '50px', fontSize: '1.5em', color: '#888' }}>
      No Article
    </div>
  );
};

const Header: React.FC = () => {
  return (
    <header style={styles.header}>
      <nav style={styles.nav}>
        <ul style={styles.navList}>
          <li style={styles.navItem}>
            <Link to="/" style={styles.navLink}>
              Home
            </Link>
          </li>
          <li style={styles.navItem}>
            <Link to="/guides" style={styles.navLink}>
              Guides
            </Link>
          </li>
          <li style={styles.navItem}>
            <Link to="/contact" style={styles.navLink}>
              Contact
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

const styles = {
  header: {
    padding: '10px 20px',
    backgroundColor: '#333',
    color: '#fff',
  },
  nav: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  navList: {
    listStyle: 'none',
    display: 'flex',
    margin: 0,
    padding: 0,
  },
  navItem: {
    margin: '0 10px',
  },
  navLink: {
    color: '#fff',
    textDecoration: 'none',
  },
};

export default Header;

export const AppRouter = () => (
  <BrowserRouter>
    {/* <Layout> */}
    {/* <Header /> */}
    <Routes>
      <Route path="/" element={<JoyTech />} />
      <Route path="/guides" element={<Guides />} />
      <Route path="/addNewGuide" element={<AddNewGuide />} />
      <Route path="/projects" element={<Projects />} />
      <Route path="/services/:title" element={<ServicePage />} />
      <Route path="/projects/project" element={<ProjectDetails />} />
      <Route path="/guides/:title" element={<GuidePage />} />
      <Route path="/articles" element={<Articles />} />
      <Route path="/article/:title" element={<Article />} />
      <Route path="/Forum" element={<Forum />} />
    </Routes>
    {/* </Layout> */}
  </BrowserRouter>
);
