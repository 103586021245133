import { Guide } from "models/Guide";
import { IProject } from "models/IProject";
import uuid from "react-uuid";

export const typingTextIntro = `ג'ויטק מספקת שירותי מחשוב, חומרה, תוכנה ותקשורת לעסקים ופרטיים.
ניתן למצוא אצלנו מגוון של פתרונות לכל עולמות הטכנולוגיה.

פתרונות מחשוב
פתרונות תוכנה
פתרונות תקשורת
מתן שירותי ייעוץ

ניתן לקבל שירות בסלולרי: 0547599797
אימייל:joytechit@gmail.com
`;

export const code = `import { ReactNode } from 'react';
import { Footer } from './footer';
import { Header } from './header';
import './layout.css';
interface LayoutProps {
  children: ReactNode;
}

export const Layout = ({ children }: LayoutProps) => (
  <div className="layout">
    <Header />
    <div className="body"> {children}</div>
    <Footer />
  </div>
);
`;


export const proj: IProject = {
  id: uuid(),
  title: 'Layout with React + Typescript',
  subject: 'React + Typescript',
  intro: 'This project is about creating Generic Layout with React + Typescript',
  details: `Project Details`,

  image: 'https://placeimg.com/640/640/nature',
  create_date: 1677504633997,
  codeList: [code]
};


export interface Patient {
  id: string,
  fName: string,
  lName: string,
}

const patients: Patient[] = [];

for (let i = 0; i < 50; i++) {
  patients.push({
    id: `${i}`,
    fName: "Patient ",
    lName: `${i}`,

  });
}

export const PATIENTS = patients;


export const articles = [
  {
    id: 'a6546',
    "title": "איך להזרים נתונים בצורה יעילה",
    image: '',
    "sections": [
      {
        "title": "מבוא",
        "content": "Streaming נתונים הפכה לשיטה חשובה לניהול ועיבוד נתונים בזמן אמת. טכנולוגיות Streaming מאפשרות לקבל ולנתח נתונים תוך כדי תנועה, מה שמספק יתרונות רבים כמו תגובה מהירה לבעיות ותמיכה בהחלטות בזמן אמת. בכתבה זו, נסקור את עקרונות היסוד של Streaming נתונים, הכלים הפופולריים ביותר בתחום, ומקרים שבהם השיטה מתאימה ביותר."
      },
      {
        "title": "הבנת Streaming נתונים",
        "subsections": [
          {
            "title": "מאפיינים",
            "content": [
              "זרימת נתונים בזמן אמת: מאפשרת קבלה וניתוח של נתונים ברגע שהם נוצרו או הגיעו, ללא צורך בעיבוד מסיבי לאחר מעשה.",
              "עיבוד נתונים רציף: מבצע ניתוח נתונים בצורה רציפה, ומאפשר טיפול בנתונים שנאספים בתדירות גבוהה.",
              "יכולת להתמודד עם נתונים בקצב גבוה: מערכות Streaming מתוכננות להתמודד עם כמות גדולה של נתונים במהירות גבוהה.",
              "ניהול משאבים: דורשת ניהול נכון של משאבים כדי להבטיח ביצועים אופטימליים ותגובה מהירה."
            ]
          },
          {
            "title": "כלים פופולריים ל-Streaming נתונים",
            "content": [
              "Apache Kafka: מערכת מבוזרת לניהול ושידור זרימות נתונים, המיועדת להתמודד עם כמות גדולה של נתונים ולספק עמידות גבוהה.",
              "Apache Flink: מספקת עיבוד נתונים בזמן אמת עם תמיכה בזרימות בלתי מוגבלות ובניתוחים מורכבים של נתונים.",
              "Apache Storm: מערכת עיבוד נתונים רציף הידועה במינימום עיכובים וביכולת להתמודד עם נתונים בקצב גבוה.",
              "Amazon Kinesis: פתרון מבית אמזון שמאפשר עיבוד נתונים בזמן אמת בסקייל גדול, עם תמיכה באספקת נתונים, עיבוד וניתוח."
            ]
          },
          {
            "title": "מקרים לשימוש",
            "content": [
              "אנליטיקה בזמן אמת: ניתוח נתונים מיידי להפקת תובנות והגבת באופן מהיר לשינויים.",
              "מערכות ניטור: ניטור ותגובה מידית לבעיות או שינויים במערכות IT ומכשירים.",
              "אפליקציות IoT: ניהול נתונים מכשירים ובקרים עם זרימת נתונים אינטנסיבית, כולל ניהול זרימות נתונים ממספר מקורות."
            ]
          }
        ]
      },
      {
        "title": "כיצד לבחור את הכלי המתאים",
        "content": [
          "דרישות בזמן אמת: כשיש צורך בניתוח נתונים מיידי ותגובה מהירה לאירועים או שינויים.",
          "סקיילינג: כשיש צורך להתמודד עם כמות גדולה של נתונים ולהתרחב בצורה גמישה.",
          "גמישות: כשיש צורך להתאים את הכלי לצרכים משתנים ולהתמודד עם סוגי נתונים שונים.",
          "מינימום עיכובים: כשחשוב להבטיח שהמערכת מספקת תשובות והגבות במהירות הגבוהה ביותר האפשרית."
        ]
      },
      {
        "title": "סיכום",
        "content": "Streaming נתונים מציעה פתרונות רבי עוצמה לניהול ועיבוד נתונים בזמן אמת. עם האפשרות להגיב במהירות לבעיות ולטפל בכמויות נתונים גדולות, חשוב לבחור את הכלי המתאים לצרכים הספציפיים של הארגון. כלים כמו Apache Kafka, Apache Flink, Apache Storm ו-Amazon Kinesis מציעים פתרונות שונים בהתאם לצרכים של עיבוד נתונים רציף, ניהול משאבים וסקיילינג. הבנה מעמיקה של הכלים והמאפיינים שלהם תסייע בבחירה נכונה ותשפר את היעילות של ניהול הנתונים."
      },

    ],
    "sources": [
      "Arduino Official Website: https://www.arduino.cc/",
      "SparkFun Learn: https://learn.sparkfun.com/tutorials/what-is-an-arduino/all",
      "Control.com: https://control.com/technical-articles/introduction-to-arduino-history-hardware-and-software/"
    ],
    "created_at": "31/07/2024",
    "type": 'תוכנה'
  },
  {
    id: 'asfa6',
    "title": "איך לבחור את מערכת ההפעלה המתאימה לעסק שלך",
    image: 'https://lemppics.lemp.io/1661682262805.jpeg',
    "sections": [
      {
        "title": "מבוא",
        "content": "בחירת מערכת הפעלה (OS) מתאימה היא קריטית להצלחת העסק, שכן היא משפיעה על ביצועים, אבטחה, עלות ותמיכה. מערכת ההפעלה מהווה את הבסיס לכל תוכנה ורכיב חומרה שיבחר העסק, ולכן יש לקחת בחשבון מספר גורמים חשובים בבחירה. במאמר זה נסקור את סוגי מערכות ההפעלה הפופולריות, היתרונות והחסרונות של כל אחת, ונתאר כיצד לבחור את המערכת המתאימה ביותר לצרכים העסקיים שלך."
      },
      {
        "title": "סוגי מערכות הפעלה",
        "subsections": [
          {
            "title": "Windows",
            "content": [
              "נפוצה בשוק: מערכת ההפעלה של מיקרוסופט נחשבת לנפוצה ביותר בשוק העסקים, ונתמכת על ידי מגוון רחב של יצרנים ומפתחים.",
              "תמיכה רחבה בתוכנות: יש תמיכה נרחבת בתוכנות עסקיות רבות, כולל תוכנות לניהול עסקים, גרפיקה, ועוד.",
              "עדכונים ואבטחה: מיקרוסופט מספקת עדכונים שוטפים הכוללים תיקוני אבטחה ופיצ'רים חדשים, אך קיימת התמודדות עם סוגיות אבטחה לעיתים.",
              "עלות: מערכת ההפעלה לא תמיד זולה ודורשת רישיונות עבור משתמשים רבים, מה שעשוי להוות חסרון משמעותי עבור עסקים קטנים."
            ]
          },
          {
            "title": "macOS",
            "content": [
              "ממשק משתמש מתקדם: מערכת ההפעלה של אפל ידועה בעיצוב המתקדם ובממשק משתמש אינטואיטיבי וידידותי.",
              "אבטחה: macOS נחשבת למערכת עם רמת אבטחה גבוהה הודות למערכת ההפעלה הייחודית ולמדיניות האבטחה של אפל.",
              "תאימות: אפשרות טובה לעסקים המשתמשים בחומרה ובתוכנות של אפל, עם תמיכה בתוכנות מקצועיות כמו Final Cut Pro ו-Logic Pro.",
              "עלות: חומרת אפל לרוב יקרה יותר, מה שיכול להוות חסרון בהשוואה לחומרה שתומכת במערכות אחרות."
            ]
          },
          {
            "title": "Linux",
            "content": [
              "קוד פתוח: מערכת הפעלה קוד פתוח המאפשרת התאמה אישית וגמישות גבוהה, עם גרסאות רבות המיועדות לצרכים שונים.",
              "עלויות נמוכות: בדרך כלל אינה דורשת רישיונות יקרים, ומספקת אפשרויות רבות להורדה בחינם.",
              "אבטחה: נחשבת למערכת עם רמת אבטחה גבוהה במיוחד עם עדכונים ותצורות נכונות, אך עלולה לדרוש ידע טכני נוסף.",
              "תמיכה: דורשת יותר ידע טכני להתקנה ולתמיכה, ולעיתים לא תומכת בתוכנות מסוימות או יישומים עסקיים פופולריים."
            ]
          },
          {
            "title": "Chrome OS",
            "content": [
              "קלות שימוש: מערכת הפעלה פשוטה שמבוססת בעיקר על עבודה בענן, ומספקת חווית משתמש חלקה ופשוטה.",
              "אבטחה: מערכת עם רמת אבטחה גבוהה בזכות עדכונים אוטומטיים והגנה מתקדמת מפני תוכנות זדוניות.",
              "תמיכה בתוכנות: מגבלות בתמיכה בתוכנות מקומיות וביישומים שאינם מבוססים על אינטרנט, מה שעלול להגביל את השימושים העסקיים.",
              "עלות: לרוב יש עלויות נמוכות יותר מבחינת חומרה, כאשר מחשבים עם Chrome OS נוטים להיות זולים יותר."
            ]
          }
        ]
      },
      {
        "title": "איך לבחור את מערכת ההפעלה המתאימה",
        "content": [
          "צרכים עסקיים: להבין את הצרכים הספציפיים של העסק, כולל תוכנות חיוניות, דרישות חומרה, ויישומים חיוניים. לדוגמה, אם העסק דורש תוכנות גרפיקה מקצועיות, ייתכן ש-macOS תהיה הבחירה הנכונה.",
          "תקציב: לקבוע את התקציב הכולל כולל עלויות רישיונות, חומרה, ותמיכה. עלויות רישיונות ל-Windows עשויות להיות גבוהות, אך יתכן שהן משתלמות במקרה של שימוש נרחב בתוכנות תואמות.",
          "אבטחה: לבחור מערכת הפעלה המתאימה לדרישות האבטחה של העסק. אם אבטחה היא גורם קרדינלי, macOS ו-Linux עשויות להיות בחירות טובות במיוחד.",
          "תמיכה טכנית: לבדוק את התמיכה הטכנית הזמינה למערכת ההפעלה ולהבין את הצרכים להכשרה ולתחזוקה. אם הצוות הטכני שלך מיומן ב-Linux, זו עשויה להיות אופציה טובה."
        ]
      },
      {
        "title": "סיכום",
        "content": "בחירת מערכת ההפעלה המתאימה היא החלטה חשובה שיכולה להשפיע על ביצועי העסק, עלות ותמיכה. Windows, macOS, Linux ו-Chrome OS מציעות יתרונות וחסרונות שונים, ולכן יש לבחור את המערכת שתואמת בצורה הטובה ביותר לצרכים העסקיים שלך. הבנת היתרונות והחסרונות של כל מערכת תסייע בקבלת החלטה מושכלת שתשפר את היעילות של פעילות העסק, ותספק תמיכה מותאמת לצרכים העתידיים."
      }
    ],
    "sources": [
      "Arduino Official Website: https://www.arduino.cc/",
      "SparkFun Learn: https://learn.sparkfun.com/tutorials/what-is-an-arduino/all",
      "Control.com: https://control.com/technical-articles/introduction-to-arduino-history-hardware-and-software/"
    ],
    "created_at": "01/08/2024",
    "type": "תוכנה"
  },
  {
    "id": "ai123",
    "title": "The Rise of Artificial Intelligence",
    image: 'https://netop.cloud/wp-content/uploads/2023/04/AI-iStock-1384979710-scaled.jpg',
    "sections": [
      {
        "title": "Introduction",
        "content": "Artificial Intelligence (AI) has rapidly evolved from a concept to a tangible reality, transforming industries and our daily lives. From self-driving cars to medical diagnosis, AI is making significant strides."
      },
      {
        "title": "Types of AI",
        "subsections": [
          {
            "title": "Narrow AI",
            "content": ["Focused on specific tasks, such as image recognition or speech recognition."]
          },
          {
            "title": "General AI",
            "content": ["Hypothetical AI with human-level intelligence and understanding."]
          }
        ]
      },
      {
        "title": "Impact of AI",
        "content": ["AI is revolutionizing healthcare, finance, transportation, and more. However, ethical considerations and job displacement are key concerns."]
      }
    ],
    "sources": [
      "Arduino Official Website: https://www.arduino.cc/",
      "SparkFun Learn: https://learn.sparkfun.com/tutorials/what-is-an-arduino/all",
      "Control.com: https://control.com/technical-articles/introduction-to-arduino-history-hardware-and-software/"
    ],
    "created_at": "2024-08-02T10:38:53Z",
    "type": "technology"
  },
  {
    "id": "article_arduino",
    "title": "Arduino: A Beginner-Friendly Platform for Electronics",
    image: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fstatic1.makeuseofimages.com%2Fwordpress%2Fwp-content%2Fuploads%2F2021%2F06%2FArduino-Project.jpg&f=1&nofb=1&ipt=2fe92b69937823d5258b90fff74b86656559538722e9b1e6c383bb939294ded4&ipo=images  ',
    "sections": [
      {
        "title": "מבוא",
        "content": "Arduino היא פלטפורמת אלקטרוניקה פתוחה שמבוססת על חומרה ותוכנה קלים לשימוש. היא נועדה להיות נגישה לכל רמות המיומנות, בין אם אתה חובבן, תלמיד או מקצוען. Arduino יכולה להיות כלי חזק לפרויקטים שלך."
      },
      {
        "title": "הבנת Arduino",
        "subsections": [
          {
            "title": "חומרה",
            "content": [
              "לוח פיזי שמכיל מיקרו-בקר, פינים לקלט/פלט, ורכיבים נוספים. קיימים מספר לוחות Arduino, כל אחד עם יכולות ומפרטים שונים."
            ]
          },
          {
            "title": "תוכנה",
            "content": [
              "סביבת הפיתוח המשולבת (IDE) של Arduino, המשמשת לכתיבת קוד והעלאתו ללוח. התוכנה מבוססת על שפת התכנות Processing, מה שמקל על לימוד מתחילים."
            ]
          }
        ]
      },
      {
        "title": "למה לבחור ב-Arduino",
        "content": [
          "פשטות: Arduino נועדה להיות ידידותית למשתמש, עם תיעוד ברור וקהילה גדולה של משתמשים.",
          "גמישות: ניתן להשתמש ב-Arduino למגוון רחב של פרויקטים, ממהבהוב פשוט של נורת LED ועד רובוטיקה מורכבת ויישומי IoT.",
          "קוד פתוח: החומרה והתוכנה הן בקוד פתוח, מה שמאפשר התאמה אישית וניסויים.",
          "עלות-תועלת: לוחות Arduino בדרך כלל זולים, מה שמאפשר גישה רחבה."
        ]
      },
      {
        "title": "פרויקטים נפוצים עם Arduino",
        "content": [
          "רובוטיקה: בניית רובוטים עם חיישנים, מנועים ומפעילים.",
          "אוטומציה ביתית: יצירת מכשירים חכמים לבית כמו בקרת תאורה או חיישני טמפרטורה.",
          "לבישים: פיתוח מכשירים אלקטרוניים ללבישה, כמו שעוני כושר או שעונים חכמים.",
          "מיצבים אומנותיים אינטראקטיביים: יצירת יצירות אומנות מרתקות ואינטראקטיביות.",
          "רישום נתונים: איסוף ואחסון נתונים מחיישנים."
        ]
      },
      {
        "title": "כיצד להתחיל",
        "content": [
          "לוח Arduino: בחר לוח שמתאים לדרישות הפרויקט שלך.",
          "Arduino IDE: הורד והתקן את תוכנת Arduino מהאתר הרשמי.",
          "ידע בסיסי באלקטרוניקה: הבנה של מושגים כמו מתח, זרם, נגדים ומעגלים היא מועילה."
        ]
      }
    ],
    "created_at": "2024-08-02",
    "type": "תוכנה",
    "sources": [
      "Arduino Official Website: https://www.arduino.cc/",
      "SparkFun Learn: https://learn.sparkfun.com/tutorials/what-is-an-arduino/all",
      "Control.com: https://control.com/technical-articles/introduction-to-arduino-history-hardware-and-software/"
    ]
  }


]


// export const guides = [

//   {
//     title: 'Introduction to Software Development',
//     description:
//       'A comprehensive guide covering the basics of software development, including programming languages, version control, and software development methodologies.',
//     field: 'Software Computing',
//     example_guide: {
//       title: 'Quick Start: Basics of Software Development',
//       steps: [
//         'Choose a programming language to learn (e.g., Python, JavaScript).',
//         'Set up your development environment (install IDE, text editor).',
//         "Write and run a simple 'Hello, World!' program.",
//         'Understand the basics of version control using Git.',
//         'Explore fundamental software development methodologies (Agile, Waterfall).',
//       ],
//     },
//   },
//   {
//     title: 'Building RESTful APIs with Node.js',
//     description:
//       'Step-by-step guide on how to create RESTful APIs using Node.js, including best practices and security considerations.',
//     field: 'Software Computing',
//     example_guide: {
//       title: 'Quick Start: Creating a RESTful API with Node.js',
//       steps: [
//         'Install Node.js and Express framework.',
//         'Set up a new Express project.',
//         'Create a simple GET endpoint.',
//         'Test the API using Postman or a similar tool.',
//         'Understand and implement basic error handling.',
//       ],
//     },
//   },
//   {
//     title: 'Getting Started with DevOps',
//     description:
//       'An introduction to DevOps practices and tools, including continuous integration, continuous deployment, and infrastructure as code.',
//     field: 'IT',
//     example_guide: {
//       title: 'Quick Start: DevOps Basics',
//       steps: [
//         'Understand the core principles of DevOps.',
//         'Set up a simple CI/CD pipeline using GitHub Actions.',
//         'Implement automated testing in the pipeline.',
//         'Deploy an application to a cloud provider (e.g., AWS, Azure).',
//         'Monitor the deployment process and gather basic metrics.',
//       ],
//     },
//   },
//   {
//     title: 'Cloud Computing Essentials',
//     description:
//       "A beginner's guide to cloud computing concepts, providers, and services. Learn about IaaS, PaaS, and SaaS models.",
//     field: 'IT',
//     example_guide: {
//       title: 'Quick Start: Cloud Computing Basics',
//       steps: [
//         'Understand the different cloud service models (IaaS, PaaS, SaaS).',
//         'Sign up for a free tier account on a cloud provider (e.g., AWS, Azure, GCP).',
//         "Launch a virtual machine (VM) using the cloud provider's console.",
//         'Deploy a simple web application on the VM.',
//         'Explore basic cloud storage options and store some sample data.',
//       ],
//     },
//   },
//   {
//     title: 'Introduction to Internet of Things (IoT)',
//     description:
//       'An overview of IoT concepts, architecture, and applications, including hardware and software components.',
//     field: 'IoT',
//     example_guide: {
//       title: 'Quick Start: Understanding IoT',
//       steps: [
//         'Learn the basic components of an IoT system (sensors, actuators, gateways).',
//         'Set up a simple IoT device (e.g., Raspberry Pi, Arduino).',
//         'Connect the device to the internet.',
//         'Collect and send data from a sensor to a cloud service.',
//         'Visualize the collected data using a basic dashboard.',
//       ],
//     },
//   },
//   {
//     title: 'Building IoT Solutions with Arduino',
//     description:
//       'A hands-on guide to creating IoT projects using Arduino, covering sensors, actuators, and communication protocols.',
//     field: 'IoT',
//     example_guide: {
//       title: 'Quick Start: Creating an IoT Project with Arduino',
//       steps: [
//         'Set up the Arduino development environment.',
//         'Connect a sensor (e.g., temperature, humidity) to the Arduino.',
//         'Write a simple program to read sensor data.',
//         'Send the sensor data to a cloud platform (e.g., ThingSpeak).',
//         "Visualize the data on the cloud platform's dashboard.",
//       ],
//     },
//   },
//   {
//     title: 'Data Structures and Algorithms in Python',
//     description:
//       'A detailed guide on understanding and implementing common data structures and algorithms using Python.',
//     field: 'Software Computing',
//     example_guide: {
//       title: 'Quick Start: Data Structures in Python',
//       steps: [
//         'Set up a Python development environment.',
//         'Implement a basic data structure (e.g., linked list, stack).',
//         'Write a function to perform an operation on the data structure (e.g., add, remove).',
//         'Understand and implement a simple algorithm (e.g., binary search).',
//         'Test the data structure and algorithm with sample data.',
//       ],
//     },
//   },
//   {
//     title: 'Mastering Kubernetes for Container Orchestration',
//     description:
//       'An in-depth guide to deploying, managing, and scaling applications using Kubernetes.',
//     field: 'IT',
//     example_guide: {
//       title: 'Quick Start: Using Kubernetes',
//       steps: [
//         'Install Kubernetes (e.g., Minikube) on your local machine.',
//         'Deploy a simple application (e.g., Nginx) to Kubernetes.',
//         'Scale the application using Kubernetes commands.',
//         'Implement basic monitoring for the deployed application.',
//         'Understand and create a simple Helm chart for the application.',
//       ],
//     },
//   },
//   {
//     title: 'Cybersecurity Fundamentals',
//     description:
//       'A guide to understanding the basics of cybersecurity, including threat models, common vulnerabilities, and security best practices.',
//     field: 'IT',
//     example_guide: {
//       title: 'Quick Start: Cybersecurity Basics',
//       steps: [
//         'Understand common cybersecurity threats (e.g., phishing, malware).',
//         'Set up basic security measures (e.g., firewalls, antivirus).',
//         'Learn about password best practices and implement strong passwords.',
//         'Perform a basic security audit on a sample system.',
//         'Understand and implement data encryption for sensitive information.',
//       ],
//     },
//   },
//   {
//     title: 'Developing Mobile Applications with React Native',
//     description:
//       'A comprehensive guide to building cross-platform mobile applications using React Native, covering components, navigation, and state management.',
//     field: 'Software Computing',
//     example_guide: {
//       title: 'Quick Start: React Native Mobile App',
//       steps: [
//         'Set up the React Native development environment.',
//         'Create a new React Native project.',
//         'Build a simple user interface with React Native components.',
//         'Implement basic navigation between screens.',
//         "Manage state using React Native's state management tools (e.g., Redux).",
//       ],
//     },
//   },
//   {
//     title: 'Introduction to Machine Learning',
//     description:
//       "A beginner's guide to machine learning concepts, algorithms, and practical implementation using Python.",
//     field: 'Software Computing',
//     example_guide: {
//       title: 'Quick Start: Machine Learning Basics',
//       steps: [
//         'Set up a Python environment with machine learning libraries (e.g., scikit-learn).',
//         'Load a sample dataset (e.g., Iris dataset).',
//         'Preprocess the data (e.g., normalization, splitting).',
//         'Train a simple machine learning model (e.g., decision tree).',
//         "Evaluate the model's performance using basic metrics.",
//       ],
//     },
//   },
//   {
//     title: 'Setting Up a Home IoT Network',
//     description:
//       'A practical guide to setting up and securing a home IoT network, including device management and network configuration.',
//     field: 'IoT',
//     example_guide: {
//       title: 'Quick Start: Home IoT Network Setup',
//       steps: [
//         'Identify and gather your IoT devices.',
//         'Connect the devices to your home Wi-Fi network.',
//         'Set up a central hub or controller for managing the devices.',
//         'Implement basic security measures (e.g., strong passwords, network segmentation).',
//         'Monitor the devices using a simple dashboard or app.',
//       ],
//     },
//   },
//   {
//     title: 'Virtualization and Cloud Infrastructure',
//     description:
//       'An introduction to virtualization technologies and their role in modern cloud infrastructure.',
//     field: 'IT',
//     example_guide: {
//       title: 'Quick Start: Virtualization Basics',
//       steps: [
//         'Understand the concept of virtualization and its benefits.',
//         'Install a virtualization software (e.g., VirtualBox).',
//         'Create and configure a virtual machine (VM).',
//         'Deploy a sample application on the VM.',
//         'Understand the basics of VM management and scaling.',
//       ],
//     },
//   },
//   {
//     title: 'Blockchain for Beginners',
//     description:
//       'A guide to understanding blockchain technology, its applications, and how to develop blockchain-based solutions.',
//     field: 'Software Computing',
//     example_guide: {
//       title: 'Quick Start: Blockchain Basics',
//       steps: [
//         'Understand the basic principles of blockchain technology.',
//         'Set up a local blockchain environment (e.g., using Ganache).',
//         'Create a simple smart contract using Solidity.',
//         'Deploy the smart contract to the local blockchain.',
//         'Interact with the smart contract using a basic user interface.',
//       ],
//     },
//   },
//   {
//     title: 'Building Smart Home Applications with IoT',
//     description:
//       'A guide to creating smart home applications using IoT devices, focusing on automation, control, and monitoring.',
//     field: 'IoT',
//     example_guide: {
//       title: 'Quick Start: Smart Home Application',
//       steps: [
//         'Select IoT devices for your smart home application (e.g., smart lights, thermostats).',
//         'Set up a central hub or controller for the devices.',
//         'Create a basic automation script (e.g., turning lights on/off).',
//         'Implement remote control capabilities using a mobile app.',
//         'Monitor the devices and collect usage data for analysis.',
//       ],
//     },
//   },
// ];




// export const guides=[  {
//   "title": "User Authentication",
//   "sections": [
//     {
//       "title": "Introduction",
//       "duration": "3 minutes",
//       "content": "Understanding user authentication is crucial for securing any application. This guide explores various authentication methods and their best use cases."
//     },
//     {
//       "title": "Key Factors to Consider",
//       "subsections": [
//         {
//           "title": "Security",
//           "points": [
//             "Multi-factor authentication (MFA)",
//             "Password complexity requirements",
//             "Encryption of credentials"
//           ]
//         },
//         {
//           "title": "Usability",
//           "points": [
//             "Ease of use for end users",
//             "Single sign-on (SSO) support",
//             "User experience during login and registration"
//           ]
//         },
//         {
//           "title": "Integration",
//           "points": [
//             "Compatibility with existing systems",
//             "Support for OAuth, SAML, and OpenID Connect",
//             "APIs and SDKs for implementation"
//           ]
//         },
//         {
//           "title": "Cost",
//           "points": [
//             "Licensing fees",
//             "Maintenance and support costs"
//           ]
//         }
//       ]
//     },
//     {
//       "title": "Password-Based Authentication",
//       "duration": "4 minutes",
//       "content": "Password-based authentication is the most common method, relying on a username and password to verify user identity.",
//       "subsections": [
//         {
//           "title": "Core Concepts",
//           "points": [
//             "Usernames and passwords",
//             "Password storage and hashing",
//             "Password reset mechanisms"
//           ]
//         },
//         {
//           "title": "Advantages",
//           "points": [
//             "Familiar to users",
//             "Simple to implement"
//           ]
//         },
//         {
//           "title": "Disadvantages",
//           "points": [
//             "Vulnerable to attacks (e.g., brute force, phishing)",
//             "User inconvenience due to password complexity requirements"
//           ]
//         }
//       ]
//     },
//     {
//       "title": "Multi-Factor Authentication (MFA)",
//       "duration": "6 minutes",
//       "content": "MFA enhances security by requiring multiple forms of verification, such as a password and a one-time code sent to a mobile device.",
//       "subsections": [
//         {
//           "title": "Types",
//           "points": [
//             "SMS-based codes",
//             "Authenticator apps (e.g., Google Authenticator)",
//             "Biometric verification (e.g., fingerprint, facial recognition)"
//           ]
//         },
//         {
//           "title": "Advantages",
//           "points": [
//             "Increased security",
//             "Reduced risk of account compromise"
//           ]
//         },
//         {
//           "title": "Disadvantages",
//           "points": [
//             "Increased complexity",
//             "Potential for user frustration"
//           ]
//         }
//       ]
//     },
//     {
//       "title": "Choosing the Right Authentication Method",
//       "duration": "5 minutes",
//       "points": [
//         "Assess security needs: Determine the level of security required for your application.",
//         "Consider usability: Balance security with user convenience.",
//         "Evaluate integration: Ensure the chosen method integrates well with your existing systems.",
//         "Cost analysis: Weigh the costs of implementation and maintenance.",
//         "Test with users: Conduct usability testing to identify potential issues."
//       ]
//     },
//     {
//       "title": "Conclusion",
//       "duration": "2 minutes",
//       "content": "Selecting the right authentication method is critical for securing your application and ensuring a positive user experience. Carefully evaluate your needs and test thoroughly to find the best fit."
//     }
//   ]
// },
// {
//   title:'Cloud Migration',
//   sections:[
//     {
//       title: 'Introduction',
//       duration: '3 minutes',
//       content:
//         'Migrating to the cloud can offer significant benefits in terms of scalability, cost, and performance. This guide outlines the key considerations and steps involved in a successful cloud migration.',
//     },
//     {
//       title: 'Key Factors to Consider',
//       subsections: [
//         {
//           title: 'Migration Strategy',
//           points: [
//             'Lift and shift',
//             'Refactor for cloud',
//             'Replatforming',
//           ],
//         },
//         {
//           title: 'Cost Management',
//           points: [
//             'Cloud pricing models',
//             'Cost optimization strategies',
//           ],
//         },
//         {
//           title: 'Security and Compliance',
//           points: [
//             'Data encryption',
//             'Compliance with regulations (e.g., GDPR, HIPAA)',
//           ],
//         },
//         {
//           title: 'Performance',
//           points: ['Scalability', 'Latency', 'Throughput'],
//         },
//         {
//           title: 'Downtime',
//           points: ['Minimizing downtime during migration', 'Rollback plans'],
//         },
//       ],
//     },
//     {
//       title: 'Planning Your Migration',
//       duration: '4 minutes',
//       content:
//         'Effective planning is essential for a successful cloud migration. This section covers the steps involved in preparing for the move.',
//       subsections: [
//         {
//           title: 'Assessment',
//           points: [
//             'Evaluate current infrastructure',
//             'Identify dependencies and potential challenges',
//           ],
//         },
//         {
//           title: 'Choosing the Right Cloud Provider',
//           points: [
//             'Comparison of major providers (AWS, Azure, Google Cloud)',
//             'Considerations for selecting a provider',
//           ],
//         },
//         {
//           title: 'Developing a Migration Plan',
//           points: [
//             'Timeline and milestones',
//             'Resource allocation',
//           ],
//         },
//       ],
//     },
//     {
//       title: 'Execution',
//       duration: '6 minutes',
//       content:
//         'The execution phase involves the actual migration of applications and data to the cloud.',
//       subsections: [
//         {
//           title: 'Migration Tools',
//           points: [
//             'Overview of available tools and services',
//             'Selecting the right tools for your needs',
//           ],
//         },
//         {
//           title: 'Data Migration',
//           points: [
//             'Strategies for moving data',
//             'Ensuring data integrity and security',
//           ],
//         },
//         {
//           title: 'Application Migration',
//           points: [
//             'Steps for migrating applications',
//             'Testing and validation',
//           ],
//         },
//       ],
//     },
//     {
//       title: 'Post-Migration',
//       duration: '5 minutes',
//       points: [
//         'Monitoring and optimization: Continuously monitor performance and optimize as needed.',
//         'Cost management: Regularly review and adjust cost management strategies.',
//         'Security: Ensure ongoing compliance and security measures are in place.',
//       ],
//     },
//     {
//       title: 'Conclusion',
//       duration: '2 minutes',
//       content:
//         'A successful cloud migration requires careful planning, execution, and ongoing management. By following best practices, you can achieve the benefits of cloud computing while minimizing risks.',
//     },
//   ]
// }
// ]

export const news = [
  {
    title: "React 19: A Game-Changer for Modern Web Development",
    brief: "What's new in React 19? Why do you have to know it?",
    url: "https://dev.to/vyan/react-19-a-game-changer-for-modern-web-development-1bih",
    image: "https://media.dev.to/cdn-cgi/image/width=1000,height=420,fit=cover,gravity=auto,format=auto/https%3A%2F%2Fdev-to-uploads.s3.amazonaws.com%2Fuploads%2Farticles%2Fko42x3apigcvt27hdf3p.jpg",
    date: "15/01/24"
  },
  
  {
    title: "מחקר חדש מגלה מי בסכנה גדולה יותר לפיטורים בהייטק",
    brief: 'מתברר שגם בפיטורים, לא כולן וכולם נולדו שווים ',
    url: "https://www.geektime.co.il/firing-report-rise-2024/",
    image: "https://cdn.geektime.co.il/wp-content/uploads/2024/07/tim-gouw-1K9T5YiZ2WU-unsplash-1024x685.jpg",
    date: '24/07/24'
  },


];

export const customerList = [
  { name: 'HadarNet', url: 'https://hadarnet.com/wp-content/uploads/2021/01/2-1.png' },

]

export const professionList: { text?: string; image?: any; hover?: string }[] = [
  // {
  //   text: 'טכנולוגיה',
  //   image:'https://i0.wp.com/www.2scaleup.com/wp-content/uploads/2019/01/stockvault-information-technology-concept174322.jpg?w=2900&ssl=1',
  //   hover: 'Networking, Sys Admin, Servers, Hardware',
  // },
  {
    text: 'מחשבים',
    image:
      'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse4.mm.bing.net%2Fth%3Fid%3DOIP.Njr_ya9PLVXD4Ae4DhEyNwHaEd%26pid%3DApi&f=1&ipt=0aa90bfc10dd32956558f5c855436ba38c891cba7903998e04b32b68a4a3a499&ipo=images',
    hover: 'מחשבים, מערכות הפעלה, חומרה',
  },
  {
    text: 'תוכנה',
    image: 'https://i.pcmag.com/imagery/roundups/02HDufdqeRUDu3tl0NnY2qZ-2.fit_lim.size_760x427.v1649351854.jpg',
    hover: 'Front, Back, FullStack, Arduino',
  },
  {
    text: 'IT',
    image: 'https://www.augustatech.edu/skins/userfiles/images/IT-images/it-services.jpg',
    hover: 'Networking, Sys Admin, Servers, Hardware',
  },
  {
    text: 'חומרה',
    image:
      'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse4.mm.bing.net%2Fth%3Fid%3DOIP.Njr_ya9PLVXD4Ae4DhEyNwHaEd%26pid%3DApi&f=1&ipt=0aa90bfc10dd32956558f5c855436ba38c891cba7903998e04b32b68a4a3a499&ipo=images',
    hover: 'Computer and Laptops, PCB Scales',
  },
  {
    text: 'אינטגרציה',
    image:
      'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse4.mm.bing.net%2Fth%3Fid%3DOIP.Njr_ya9PLVXD4Ae4DhEyNwHaEd%26pid%3DApi&f=1&ipt=0aa90bfc10dd32956558f5c855436ba38c891cba7903998e04b32b68a4a3a499&ipo=images',
    hover: 'Front to Back, Back to front, Hardware to Back, Hardware to Front',
  },
  {
    text: 'IOT',
    image:
      'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse4.mm.bing.net%2Fth%3Fid%3DOIP.Njr_ya9PLVXD4Ae4DhEyNwHaEd%26pid%3DApi&f=1&ipt=0aa90bfc10dd32956558f5c855436ba38c891cba7903998e04b32b68a4a3a499&ipo=images',
    hover: 'Arduino, Raspberry Pie, BLE',
  },

]