import { useState } from 'react';
import { AnimatedButtonProps } from 'ui-kit/animated';
import { imageHandler } from 'utils/ImageHandler';

import './style.css';

export const HoverCard = ({
  children,
  hover = 'No Hover Content',
  onClick,
  text = 'No Text',
  image,
  style,
}: AnimatedButtonProps) => {
  const [isHover, setIsHover] = useState<boolean>(false);

  return (
    <div
      onClick={onClick}
      onMouseLeave={() => setIsHover(false)}
      onMouseEnter={() => setIsHover(true)}
      className="animated"
    >
      {/* {isHover && <div className="animatedHover">{hover}</div>} */}
      {isHover && <div className="animatedHover">{hover}</div>}
      {children ?? (
        <div
          className="children"
          style={{
            ...style,
          }}
        >
          <div
            // style={{
            //   display: 'flex',
            //   height: 300,
            //   width: 500,
            //   justifyContent: 'center',
            //   // alignItems:'center'
            // }}
            className='img-container'
          >
            <img
              alt={text}
              src={image || imageHandler('noImage')}
              // style={{
              //   width: '100%',
              // }}
              className="img"
            />
          </div>
          <h2>{text || 'No Text'}</h2>
        </div>
      )}
    </div>
  );
};
