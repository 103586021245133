import { CSSProperties, useEffect, useState } from 'react';

interface AutoTypingProps {
  texts: string[];
  typingSpeed?: number;
  pauseTime?: number;
  fontSize?: number;
  style?:CSSProperties
}

export const AutoTyping: React.FC<AutoTypingProps> = ({
  texts,
  typingSpeed = 80,
  pauseTime = 1500,
  fontSize = 20,
  style
}) => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [displayedText, setDisplayedText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [showCursor, setShowCursor] = useState(true);

  useEffect(() => {
    let typingTimeout: NodeJS.Timeout;

    const handleTyping = () => {
      const fullText = texts[currentTextIndex];
      if (isDeleting) {
        setDisplayedText(fullText.substring(0, displayedText.length - 1));
        if (displayedText === '') {
          setIsDeleting(false);
          setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
        }
      } else {
        setDisplayedText(fullText.substring(0, displayedText.length + 1));
        if (displayedText === fullText) {
          typingTimeout = setTimeout(() => setIsDeleting(true), pauseTime);
          return;
        }
      }
      typingTimeout = setTimeout(handleTyping, typingSpeed);
    };

    typingTimeout = setTimeout(handleTyping, typingSpeed);

    const cursorInterval = setInterval(() => {
      setShowCursor((prev) => !prev);
    }, 400);

    return () => {
      clearTimeout(typingTimeout);
      clearInterval(cursorInterval);
    };
  }, [displayedText, isDeleting, texts, currentTextIndex, typingSpeed, pauseTime]);

  return (
    <div
      style={{
        color: 'white',
        fontSize,
        ...style
        // border:'1px solid '
      }}
    >
      <span>{displayedText}</span>
      {showCursor && <span className="cursor">|</span>}
    </div>
  );
};
