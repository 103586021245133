import { apiUrl } from 'consts/url';
import { IProject } from 'models/IProject';
import { Api } from './api';

export class ProjectService {
  static getAll() {
    return Api.get<IProject[]>(apiUrl().projects.getAll);
  }

  static getPage(page: number) {
    return Api.get<IProject[]>(apiUrl({page}).projects.getPage);
  }

  static update(project: IProject) {
    return Api.put<IProject>(apiUrl().projects.update, project);
  }

  static delete() {}

  static create() {}
}
