import {  useState, useEffect, useMemo, CSSProperties } from 'react';

import { PaginationBar } from './PaginationBar';
import './pagination.css';

interface PaginationLayoutProps<T> {
  title?: string;
  list: T[];
  pageSize?: number;
  renderItem: (item: T) => React.ReactNode;
  bar?: boolean;
  auto?: boolean;
}

 const Ellipsis = ({
  children,
  maxLength = 25,
  style
}: {
  children: string;
  maxLength?: number;
  style?:CSSProperties
}) => {
  // const text='oshfsoiofhsdohdih'

  const [isTruncated] = useState(true);
  const truncatedText =
    children.length > maxLength && isTruncated
      ? children.substring(0, maxLength) + '...'
      : children;

  return <div
  style={style}
  >{truncatedText}</div>;
};

export const PagingLayout = <T extends unknown>({
  title = '',
  list,
  renderItem,
  pageSize = 10,
  bar = false,
  auto = false,
}: PaginationLayoutProps<T>) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;

    return list.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, pageSize, list]);

  useEffect(() => {
    const tick = setTimeout(() => {
      if (auto) {
        if (currentPage < list.length) setCurrentPage((prev) => prev + 1);
        else setCurrentPage(1);
      }
    }, 5000);
    return function cleanUp() {
      clearTimeout(tick);
    };
  }, [auto, currentPage, setCurrentPage, list.length]);

  return (
    <div className="paging-body">
      <h2>{title}</h2>

      <div className="paging-table">
        {currentTableData.map(
          (item, i) => renderItem(item),
          // <tr key={uuid()}>{
          // }</tr>
        )}
      </div>
      {bar && (
        <PaginationBar
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={list.length}
          pageSize={pageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      )}
    </div>
  );
};
