import { useLazyLoading } from 'hooks/useLazyLoading';
import path from 'path';
import { useEffect, useState, useCallback, ReactNode, useRef } from 'react';

interface InfiniteScrollProps {
  render: (item: any) => ReactNode;
  path:string,
  title?:string,
  subTitle?:string
}

export const InfiniteScrollLayout = ({  title,subTitle,render,path }: InfiniteScrollProps) => {
  const [page, setPage] = useState(0);
  const loader = useRef(null);

  const { loading, error, lazyList } = useLazyLoading({
    page,
    query: path,
  });
  const handleObserver = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const target = entries[0];
      if (target.isIntersecting) {
        setPage((prev) => prev + 1);
      }
    },
    [],
  );

  // useEffect(() => {
      
  //     console.log('Infi page: ',page);
  //   const option = {
  //     root: null,
  //     rootMargin: '20px',
  //     threshold: 0,
  //   };
  //   const observer = new IntersectionObserver(handleObserver,option);
  //   if (loader.current) observer.observe(loader.current);
  //   return function cleanup(){
  //       loader.current=null
        
  //   }
  // }, [handleObserver,page,loading]);

  return (
    <div >
      <h1>{title||'Infinite Scroll'}</h1>
      <h2>{subTitle}</h2>
      {/* <input type="text" value={query} onChange={handleChange} /> */}
      {
lazyList&&lazyList.length>0&&
      <div>{lazyList.map((item, i) => render(item))}</div>
      }
      {loading && <p>Loading...</p>}
      {error && <p>Error!</p>}
      <div ref={loader} />
    </div>
  );
};
