export const images = {
    noImage: require('../assets/pictures/problem.png'),
    logoBlack: require('../assets/pictures/logoBlack.png'),
    logoWhite: require('../assets/pictures/logoWhite.png'),
    laptop1: require('../assets/pictures/Laptop1.png'),
    laptop2: require('../assets/pictures/Laptop2.png'),
    brain: require('../assets/pictures/brain.jpg'),
    JoyTechWeb: require('../assets/pictures/JoyTechWeb.png'),
}

export type imageTypes=keyof typeof images

export const imageHandler = (pic: keyof typeof images) => {

    return pic in images ? images[pic] : images.noImage

}