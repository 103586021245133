
import { useCallback, useEffect, useState } from 'react';
import { httpRequst } from 'services/api';

export const useProfession = () => {
  const [professions, setProfessions] = useState<any[]>([]);
  const [selectedProfession, setSelectedProfession] = useState<any>();


  const handleGetProfessions = useCallback(async () => {
    let professionList = await httpRequst('GET','fields')
    setProfessions(professionList)
  }, [setProfessions]);

  const handleSelectedProfession = (profession: any) => {
    // console.log('Selected: ', profession);
    setSelectedProfession((profession:any) => profession);
  };

  useEffect(() => {
   handleGetProfessions()
  }, [handleGetProfessions])

  return { handleGetProfessions, professions, handleSelectedProfession, selectedProfession };
};
