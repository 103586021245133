import { useState, useMemo, useCallback, useEffect } from 'react';
import { IProject } from 'models/IProject';
import { ProjectService } from 'services/project';
import { useNavigate } from 'react-router';

export const useProject = () => {
  const [projects, setProjects] = useState<IProject[]>([]);
  const [selectedProject, setSelectedProject] = useState<IProject | null>();

  const getProjects = useMemo(async () => {
    let projectList = await ProjectService.getAll();
    return projectList.data;
  }, []);

  const handleGetProjects = useCallback(async () => {
    let projectList = await getProjects;
    console.log('list: ', projectList);
    setProjects((prev) => projectList);
  }, [setProjects,getProjects]);

  const handleSelectedProject = useCallback(
    (project: IProject|null) => {
      console.log('Handle: ', project);
      setSelectedProject(project);
    },
    [setSelectedProject],
  );

  useEffect(() => {
    handleGetProjects();
    console.log('Selected: ', selectedProject);
  }, [selectedProject,handleGetProjects]);


  return { handleGetProjects, projects, handleSelectedProject, selectedProject };
};
