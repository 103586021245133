interface Params {
  id?: string;
  page?:number
}

const baseUrl=' http://localhost:3001/api'

export const apiUrl = ({ id,page }: Params = {}) => ({
  guides: {
    getAll: '/guides',
    delete: `/guides/${id}`,
    update: '/guides/update',
    create: '/guides/create',
  },
  projects: {
    getAll: '/projects',
    delete: `/projects/${id}`,
    update: '/projects/update',
    create: '/projects/create',
    getPage: `${baseUrl}/projects?page=${page}`,
  },
});
