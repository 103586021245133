import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from 'ui-kit';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

// if (process.env.NODE_ENV === 'development') {
//   const { worker } = require('./mock/mocks/browser');
//   worker.start(); // await
// }

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
