import { useState } from 'react';

const AddNewGuide = () => {
  const [temp, setTemp] = useState('');
  const [tempHeader, setTempHeader] = useState('');
  const [tempCode, setTempCode] = useState('');
  const [obList, setObList] = useState<any[]>([]);

  const [compList, setCompList] = useState<JSX.Element[]>([]);

  return (
    <div
      style={{
        marginTop: '70px',
        marginBottom: '30px',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {obList.map((comp, i) => {
          return (
            <div>
              {comp.type === 'Code' && (
                <textarea
                  style={{
                    backgroundColor: '#000000',
                    color: '#ffffff',
                    height: '300px',
                    width: '600px',
                  }}
                  value={obList[i].content}
                  //   onChange={(t) => setTempCode(t.target.value)}
                  onChange={(t) => {
                    setTempCode(t.target.value);
                  }}
                />
              )}
              {JSON.stringify(comp)}
            </div>
          );
        })}
      </div>

      {/* <button onClick={() => setCompList((prev) => [...prev, <input />])}>Add Text</button> */}
      {/* <button onClick={() => setCompList((prev) => [...prev, <h1>{'Header'}</h1>])}> */}

      <div>
        <button
          onClick={() => {
            setObList((p) => [...p, { type: 'Code', content: tempCode }]);
            setTempCode('');
            // setCompList((prev) => [
            //   ...prev,
            //   <textarea
            //     style={{
            //       backgroundColor: '#000000',
            //       color: '#ffffff',
            //       height: '300px',
            //       width: '600px',
            //     }}
            //   />,
            // ]);
          }}
        >
          Add Code
        </button>

        <div>
          <button
            onClick={() => {
              if (temp.length > 0) {
                setObList((p) => [...p, { type: 'Text', content: temp }]);
                // setCompList((prev) => [...prev, <div>{temp}</div>]);
              }
              setTemp('');
            }}
          >
            Add Text
          </button>

          <input placeholder="Text" value={temp} onChange={(t) => setTemp(t.target.value)} />
        </div>

        <div>
          <button
            onClick={() => {
              if (tempHeader.length > 0) {
                setObList((p) => [...p, { type: 'Header', content: tempHeader }]);
                // setCompList((prev) => [...prev, <h1>{tempHeader}</h1>]);
              }
              setTempHeader('');
            }}
          >
            Add Header
          </button>
          <input
            placeholder="Header"
            value={tempHeader}
            onChange={(t) => setTempHeader(t.target.value)}
          />
        </div>

        <button
          onClick={() => {
            // console.log('Submit: ', compList[0]);
          }}
        >
          Submit Guide
        </button>
      </div>
    </div>
  );
};

export default AddNewGuide;
