import { CSSProperties, ReactNode } from 'react';
import './style.css';

interface CardProps {
  children?: ReactNode;
  comp?: HTMLDivElement;
  style?: CSSProperties;
  containerStyle?: CSSProperties;
  onClick?: () => void;
  className?:string
}

export const Card = ({ children, comp, style, containerStyle, onClick,className }: CardProps) => (
  <div className={className||"card"} style={style}>
    <div onClick={onClick}
    className='children_container'
    
    style={containerStyle}>
      {children}
    </div>
  </div>
);
