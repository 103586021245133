
import { useCallback, useEffect, useState } from 'react';
import { Guide } from 'models/Guide';

import { httpRequst } from 'services/api';

export const useGuide = () => {
  const [guides, setGuides] = useState<Guide[]>([]);
  const [selectedGuide, setSelectedGuide] = useState<Guide>();


  const handleGetGuides = useCallback(async () => {
    let guideList = await httpRequst('GET', 'guides')
    setGuides(guideList)
  }, [setGuides]);

  const handleSelectedGuide = (guide: Guide) => {
    // console.log('Selected: ', guide);
    setSelectedGuide((guide) => guide);
  };

  useEffect(() => {
    handleGetGuides()
  }, [handleGetGuides])

  return { handleGetGuides, guides, handleSelectedGuide, selectedGuide };
};
