import { ReactNode, useEffect, useRef } from 'react';
import './style.css';

export const AutoScroll = ({
  title,
  list,
  renderItem,
  height,
  width,
  speed = 15,
}: {
  title?: string;
  list?: any;
  renderItem?: (item: any) => ReactNode;
  height?: number;
  width?: number;
  speed?: number;
}) => {
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    if (!scrollContainer) return;

    const scrollHeight = scrollContainer.scrollHeight;
    const clientHeight = scrollContainer.clientHeight;

    const scroll = () => {
      if (scrollContainer.scrollTop + clientHeight >= scrollHeight) {
        scrollContainer.scrollTop = 0;
      } else {
        scrollContainer.scrollTop += 1;
      }
    };

    const intervalId = setInterval(scroll, 20);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div
    // style={{width}}
    >
      {title && <h2 style={{ textAlign: 'center' }}>{title}</h2>}
      <div
        className="auto-scroll-container"
        style={
          {
            height: '100vh',
            // width
            // '--height': `${height}px`,
            '--width': `${width}px`,
          } as React.CSSProperties
        }
      >
        <div
          className="auto-scroll"
          style={
            {
              '--speed': `${speed}s`,
            } as React.CSSProperties
          }
        >
          {list &&
            list.map((i: any, idx: number) => <div key={idx}>{renderItem && renderItem(i)}</div>)}
        </div>
      </div>
    </div>
  );
};
