import classnames from 'classnames';

export const PaginationItem = ({
  pageNumber,
  currentPage,
  onPageChange,
}: {
  pageNumber: number;
  currentPage: number;
  onPageChange: (currentPage: number) => void;
}) => (
  <li
    className={classnames('pagination-item', {
      checked: pageNumber === currentPage,
    })}
    onClick={() => onPageChange(pageNumber)}
  >
    {pageNumber}
  </li>
);
